import i18n from '@/plugins/i18n';

export default {
    namespaced: true,

    state: {
        language: localStorage.getItem('language'),
    },

    mutations: {
        SET_LANGUAGE (state, lang) {
            localStorage.setItem('language', lang);
            state.language = lang;
            i18n.locale = lang;
        },
    },

    actions: {
        setLanguage ({commit}, languages) {
            if (typeof languages === 'string') {
                commit('SET_LANGUAGE', languages);
            } else {
                // Zoekt in array van talen naar de eerste match met een beschikbare taal
                // Bijv. Men gebruikt navigator.languages met array: ['nl-NL', 'nl', 'en']
                //       De beschikbare talen in i18n zijn ['en', 'nl']
                //       Het eerste array wordt als eerst doorlopen en de eerste taal die
                //       ook in het tweede array zit wordt gekozen.
                //       Zo wordt altijd de voorkeur van de bezoeker gevolgd waar mogelijk
                let language = process.env.VUE_APP_I18N_LOCALE;

                for (let i = 0; i < languages.length; i++) {
                    const requested = languages[i];
                    const matchedLanguage = i18n.availableLocales.find(available => (requested.match(new RegExp('^' + available + '\\b')) ? requested : false));
                    if (matchedLanguage) {
                        language = matchedLanguage;
                        break;
                    }
                }

                commit('SET_LANGUAGE', language);
            }
        },
    },
};
