import { firestore } from 'firebase';
import { PROJECT_START, PROJECT_AGREEMENT, PROJECT_WORKPLACE, PROJECT_DESIGN, PROJECT_PROTOTYPE, PROJECT_PRODUCTION, PROJECT_STEPS_ORDER, getNextStepName } from '@/router/projects';
import { userInGroupSales } from '@/utils/authorization';
import { PROJECT_UPLOAD_AGREEMENT, PROJECT_UPLOAD_NDA, PROJECT_UPLOAD_QUOTATION, PROJECT_UPLOAD_QUOTATION_SIGNED } from '@/utils';

export const refWorkflows = firestore().collection('workflows');

const compareSteps = (stepA, stepB) => {
    const a = PROJECT_STEPS_ORDER.indexOf(stepA);
    const b = PROJECT_STEPS_ORDER.indexOf(stepB);
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    }
    return 0;
};

export const isPassed = progress => progress > 0;
export const isAvailable = progress => progress >= 0;
export const isUpcoming = progress => progress < 0;

/**
 * Test of de voortgang van het proces
 * @param progress
 * @param step
 * @returns {boolean}
 */
export const isStepPassed = (progress, step) => isPassed(compareSteps(progress, step));
export const isStepCurrent = (progress, step) => progress === step;
export const isStepUpcoming = (progress, step) => isUpcoming(compareSteps(progress, step));
// isStepPassed && isStepCurrent
export const isStepAvailable = (progress, step) => isAvailable(compareSteps(progress, step));

export const isStepApproved = (workflow, step) => {
    if (!workflow?.steps[step]) {
        return;
    }

    // Als approved ontbreekt is de stap automatisch goedgekeurd
    return typeof workflow.steps[step].approved === 'undefined' || workflow?.steps[step].approved;
};

export const isStepReleased = (workflow, step) => {
    if (!workflow?.steps[step]) {
        return;
    }

    // Als released ontbreekt is de stap automatisch vrijgegeven voor de architect
    return typeof workflow.steps[step].released === 'undefined' || workflow?.steps[step].released;
};

/**
 * Bepaal voortgang van stap
 * Let op: workflow is een object uit de state van Vuex, dus niet rechtstreeks muteren!
 * @param {Object|null} workflow
 * @param {String} step - Naam van step, zie src/router/projects.js
 * @return {number}
 */
export const getProgressIndicatorBase = (workflow, step) => {
    // Noodzakelijk vanwege async koppelen van referenties (Vuexfire)
    if (!workflow || typeof workflow !== 'object') {
        return -1;
    }

    if (!isStepAvailable(workflow.progress, step)) {
        return -1;
    }

    return isStepPassed(workflow.progress, step) ? 1 : 0;
};

/**
 * Bepaal of deze stap aanpasbaar is (enkel huidige stap en verleden stappen)
 * Let op: om een verleden stap niet aanpasbaar te maken deze functie niet gebruiken
 * @param workflow
 * @param step
 * @return {number}
 */
export const getEditableIndicatorBase = (workflow, step) => {
    // Noodzakelijk vanwege async koppelen van referenties (Vuexfire)
    if (!workflow || typeof workflow !== 'object') {
        return -1;
    }

    return isStepAvailable(workflow.progress, step) ? 1 : 0;
};

/**
 * Bepaal of stap voltooid is (aanpasbaar of niet aanpasbaar doet hier niet terzake)
 * @param workflow
 * @param step
 * @param rootGetters
 * @return {number}
 */
export const getCompletedIndicatorBase = (workflow, step, rootGetters) => {
    // Noodzakelijk vanwege async koppelen van referenties (Vuexfire)
    if (!workflow || typeof workflow !== 'object') {
        return -1;
    }

    if (userInGroupSales(rootGetters['authentication/getRoles'])) {
        return isStepPassed(workflow.progress, step) ? 1 : 0;
    }

    return isStepPassed(workflow.progress, step) && isStepApproved(workflow, step) ? 1 : 0;
};

export const approveStepBase = (workflow, step) => {
    if (!isStepAvailable(workflow?.progress, step)) {
        return;
    }

    return updateFirestoreWorkflowApproveStep(workflow, step);
};

export const unapproveStepBase = (workflow, step) => {
    if (!isStepAvailable(workflow?.progress, step)) {
        return;
    }

    return updateFirestoreWorkflowApproveStep(workflow, step, false);
};

export const releaseStepBase = (workflow, step) => {
    if (!isStepAvailable(workflow?.progress, step)) {
        return;
    }

    return updateFirestoreWorkflowReleaseStep(workflow, step);
};

export const blockStepBase = (workflow, step) => {
    if (!isStepAvailable(workflow?.progress, step)) {
        return;
    }

    return updateFirestoreWorkflowReleaseStep(workflow, step, false);
};

export const progressWorkflow = (workflow, step) => {
    if (!isStepCurrent(workflow?.progress, step)) {
        return Promise.reject('Cannot progress workflow from "' + step + '" because it is not the current step');
    }

    // Bepaal naam volgende stap
    const progress = getNextStepName(workflow?.progress);

    if (!progress) {
        return Promise.reject('Cannot determine next step after "' + step + '"');
    }

    return updateFirestoreWorkflowProgress(workflow, progress);
};

const WORKFLOW_CONTENT_START = {
    released: false,
    approved: false,
};

const WORKFLOW_CONTENT_AGREEMENT = {
    released: false,
    approved: false,
    agreement: false,
};

const WORKFLOW_CONTENT_WORKPLACE = {};

const WORKFLOW_CONTENT_DESIGN = {
    approved: false,
    design: false,
};

const WORKFLOW_CONTENT_PROTOTYPE = {
    released: false,
    approved: false,
};

const WORKFLOW_CONTENT_PRODUCTION = {
    released: false,
    approved: false,
    quotation: false,
    signed: false,
};

/**
 * Standaardstappen behorende bij een workflow
 * @returns {Object[]}
 */
const createDefaultWorkflowSteps = () => {
    const workflow = {};

    // Let op: dit bepaalt geen volgorde
    workflow[PROJECT_START] = {...WORKFLOW_CONTENT_START};
    workflow[PROJECT_AGREEMENT] = {...WORKFLOW_CONTENT_AGREEMENT};
    workflow[PROJECT_WORKPLACE] = {...WORKFLOW_CONTENT_WORKPLACE};
    workflow[PROJECT_DESIGN] = {...WORKFLOW_CONTENT_DESIGN};
    workflow[PROJECT_PROTOTYPE] = {...WORKFLOW_CONTENT_PROTOTYPE};
    workflow[PROJECT_PRODUCTION] = {...WORKFLOW_CONTENT_PRODUCTION};

    return workflow;
};

/**
 * Creeer template voor een nieuwe workflow
 * @returns {{progress: Number, steps: Object[]}}
 */
const createWorkflow = () => ({
    progress: PROJECT_START,
    steps: createDefaultWorkflowSteps(),
    users: [],
});

/**
 * Maak een workflow aan in Firestore en geef een referentie terug
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const createFirestoreWorkflow = () => {
    const workflow = createWorkflow();
    const refWorkflow = refWorkflows.doc();
    return refWorkflow.set(workflow).then(() => refWorkflow);
};

/**
 * Pas een workflow aan
 * @param docId
 * @param data
 * @param merge
 * @return {Promise<void>}
 */
const setFirestoreWorkflow = async (docId, data, merge = false) => {
    const refWorkflow = refWorkflows.doc(docId);
    return refWorkflow.set({...data}, {merge});
};

/**
 * Update een workflow
 * @param docId
 * @param data
 * @param merge
 * @return {Promise<void>}
 */
const updateFirestoreWorkflow = async (docId, data) => {
    const refWorkflow = refWorkflows.doc(docId);
    return refWorkflow.update({...data});
};

const deepCloneValues = cloneable => JSON.parse(JSON.stringify(cloneable));

/**
 * Stelt voortgang in op progress
 * @param {Object} workflow
 * @param {String} progress
 * @return {Promise<void>}
 */
export const updateFirestoreWorkflowProgress = async (workflow, progress) => {
    return updateFirestoreWorkflow(workflow.id, {progress});
};

/**
 * Keurt een stap goed
 * @param workflow
 * @param name
 * @param approved
 * @return {Promise<void>}
 */
export const updateFirestoreWorkflowApproveStep = async (workflow, name, approved = true) => {
    const updateData = {};
    updateData['steps.' + name + '.approved'] = approved;
    return updateFirestoreWorkflow(workflow.id, updateData);
};

/**
 * Geeft een stap vrij voor de architect
 * @param workflow
 * @param name
 * @param released
 * @return {Promise<void>}
 */
export const updateFirestoreWorkflowReleaseStep = async (workflow, name, released = true) => {
    const updateData = {};
    updateData['steps.' + name + '.released'] = released;
    return updateFirestoreWorkflow(workflow.id, updateData);
};

/**
 * Sla een upload op van agreement
 * @param workflow
 * @param step
 * @param type
 * @param fileEntry
 * @return {Promise<void>}
 */
export const updateFirestoreWorkflowUpload = async (workflow, step, type, fileEntry) => {
    const blnValidUpload = (step === PROJECT_AGREEMENT && [PROJECT_UPLOAD_AGREEMENT, PROJECT_UPLOAD_NDA].indexOf(type) > -1)
        || (step === PROJECT_PRODUCTION && [PROJECT_UPLOAD_QUOTATION, PROJECT_UPLOAD_QUOTATION_SIGNED].indexOf(type) > -1);

    if (!blnValidUpload) {
        return Promise.reject('Upload type "' + type + '" is not valid for step ' + step);
    }

    const updateData = {};
    updateData['steps.' + step + '.' + type] = fileEntry;
    return updateFirestoreWorkflow(workflow.id, updateData);
};

/**
 * Sla een upload op van agreement
 * @param workflow
 * @param refDesign
 * @return {Promise<void>}
 */
export const updateFirestoreWorkflowSelectedDesign = async (workflow, refDesign) => {
    const updateData = {};
    updateData['steps.' + PROJECT_DESIGN + '.design'] = refDesign;
    return updateFirestoreWorkflow(workflow.id, updateData);
};

export const temporaryFunction_ResetFirestoreWorkflow = (docId, users) => updateFirestoreWorkflow(docId, {...createWorkflow(), users});
