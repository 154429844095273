<template>
    <div v-if="project && workflow">
        <div>
            <h2 class="text-h2 pt-4 d-inline" style="vertical-align: top;" v-if="!editProjectName" v-html="$t('project.project_heading_with_name', [project.name])"></h2>

            <v-text-field
                v-if="$inGroupSales && editProjectName"
                v-model="projectName"
                :label="$t('project.name_edit')"
                append-outer-icon="mdi-content-save"
                prepend-icon="mdi-close"
                @click:prepend="editProjectName = false"
                @click:append-outer="updateProjectName"
            />

            <v-icon v-if="$inGroupSales && !editProjectName" @click="editProjectName = true" class="ml-2">mdi-pencil-outline</v-icon>

        </div>
        <v-btn :color="project.users.length >= 1 ? 'primary' : ''" class="my-5 boxed-icon" tile @click="$gotoActiveStep">
            {{ $t('workflow.view_project') }}
            <v-icon right>mdi-eye-outline</v-icon>
        </v-btn>
        <user-invite-add-dialog :project-id="project.id" :user-count="project.users.length" v-if="!$inGroupArchitects()"/>
        <v-simple-table>
            <template #default>
                <thead>
                <tr>
                    <th class="text-left">{{ $t('workflow.progress_column_step') }}</th>
                    <th>{{ $t('workflow.progress_column_status') }}</th>
                    <th>{{ $t('workflow.progress_column_action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_START)" v-if="$store.getters['workflow/progressOfStart'] >= 0">Start</span>
                        <template v-else>Start</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfStart'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td>
                        <v-btn v-if="$inGroupSales() && !$isStepReleased(PROJECT_AGREEMENT)" class="boxed-icon" :class="btnClasses" color="primary" tile @click="$releaseStep(PROJECT_AGREEMENT)">
                            <v-icon left>mdi-arrow-right</v-icon>
                            {{ $t('workflow.release_to_architect') }}
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_AGREEMENT)" v-if="$store.getters['workflow/progressOfAgreement'] >= 0">{{ $t('workflow.step_heading_agreement') }}</span>
                        <template v-else>{{ $t('workflow.step_heading_agreement') }}</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfAgreement'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td>
                        <v-btn v-if="workflow.steps[PROJECT_AGREEMENT].agreement" color="primary" class="boxed-icon" :class="btnClasses" outlined tile @click="$downloadFile(workflow.steps[PROJECT_AGREEMENT].agreement)">
                            <v-icon left>mdi-file-document-outline</v-icon>
                            {{ $t('agreement.download_agreement') }}
                        </v-btn>
                        <v-btn v-if="workflow.steps[PROJECT_AGREEMENT].nda" color="primary" class="boxed-icon" :class="btnClasses" outlined tile @click="$downloadFile(workflow.steps[PROJECT_AGREEMENT].nda)">
                            <v-icon left>mdi-file-document-outline</v-icon>
                            {{ $t('agreement.download_nda') }}
                        </v-btn>
                        <v-btn v-if="$inGroupSales() && !$isStepReleased(PROJECT_AGREEMENT)" class="boxed-icon" :class="btnClasses" color="primary" tile @click="$releaseStep(PROJECT_AGREEMENT)">
                            <v-icon left>mdi-arrow-right</v-icon>
                            {{ $t('workflow.release_to_architect') }}
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_WORKPLACE)" v-if="$store.getters['workflow/progressOfWorkplace'] >= 0">{{ $t('workflow.step_heading_workplace') }}</span>
                        <template v-else>{{ $t('workflow.step_heading_workplace') }}</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfWorkplace'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_DESIGN)" v-if="$store.getters['workflow/progressOfDesign'] >= 0">{{ $t('workflow.step_heading_design') }}</span>
                        <template v-else>{{ $t('workflow.step_heading_design') }}</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfDesign'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td>
                        <design-management-dialog v-slot="{ on, attrs }" v-if="$inGroupDesigners()" :project="project" :workflow="workflow" :key="project.id">
                            <v-btn v-bind="attrs" v-on="on" :disabled="!$isStepEditable(PROJECT_WORKPLACE)" outlined tile class="boxed-icon" :class="btnClasses">
                                <v-icon left>mdi-pencil-outline</v-icon>
                                {{ $t('workflow_design.manage') }}
                            </v-btn>
                        </design-management-dialog>

                        <workflow-design-unapprove-dialog v-slot="{ on, attrs }" v-if="$inGroupDesigners()">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon" :class="btnClasses" outlined tile elevation="0">
                                {{ $t('workflow_prototype.back_to_design') }}
                                <v-icon right>mdi-arrow-left</v-icon>
                            </v-btn>
                        </workflow-design-unapprove-dialog>
                    </td>
                </tr>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_PROTOTYPE)" v-if="$store.getters['workflow/progressOfPrototype'] >= 0">{{ $t('workflow.step_heading_prototype') }}</span>
                        <template v-else>{{ $t('workflow.step_heading_prototype') }}</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfPrototype'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td>
                        <v-btn v-if="$inGroupDesigners() && $isStepReleased(PROJECT_PROTOTYPE)" color="primary" class="boxed-icon" :class="btnClasses" outlined tile elevation="0" @click="blockPrototypeForArchitect">
                            <v-icon left>mdi-cancel</v-icon>
                            {{ $t('workflow_prototype.revoke_from_architect') }}
                        </v-btn>
                        <v-btn v-else-if="$inGroupDesigners()" :disabled="!$isStepApproved(PROJECT_PROTOTYPE)" color="primary" class="boxed-icon" :class="btnClasses" tile elevation="0" @click="releasePrototypeToArchitect">
                            <v-icon left>mdi-arrow-right</v-icon>
                            {{ $t('workflow_prototype.release_to_architect') }}
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <td class="tiemposText text-left">
                        <span class="project-step-link" @click="$gotoStep(PROJECT_PRODUCTION)" v-if="$isStepEditable(PROJECT_PRODUCTION)">{{ $t('workflow.step_heading_production') }}</span>
                        <template v-else>{{ $t('workflow.step_heading_production') }}</template>
                    </td>
                    <td>
                        <v-icon large :color="$store.getters['workflow/progressOfProduction'] | stepIconColor">mdi-check-bold</v-icon>
                    </td>
                    <td>
                        <upload-dialog v-if="$inGroupSales()" v-slot="{ on, attrs }" :upload-folder="`projects/${project.id}/quotation`" @afterupload="saveQuotationUpload">
                            <v-btn v-bind="attrs" v-on="on" :color="workflow.steps[PROJECT_PRODUCTION].quotation ? '' : 'primary'" :disabled="!$isStepEditable(PROJECT_PRODUCTION)" class="boxed-icon" :class="btnClasses" :outlined="!!workflow.steps[PROJECT_PRODUCTION].quotation" tile elevation="0">
                                <v-icon left>mdi-file-upload-outline</v-icon>
                                {{ $isStepReleased(PROJECT_PRODUCTION) ? $t('workflow_production.upload_quotation') : $t('workflow_production.upload_quotation_and_release') }}
                            </v-btn>
                        </upload-dialog>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-card v-if="$inGroupAdmin()">
            <v-card-title>Testing functionality</v-card-title>
            <v-btn @click="tmpAdminResetWorkflow">Reset project workflow</v-btn>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { stepIconColor } from '@/utils/vue-filters';
import UserInviteAddDialog from '@/components/dialogs/UserInviteAddDialog';
import UploadDialog from '@/components/dialogs/UploadDialog';
import { temporaryFunction_ResetFirestoreWorkflow, updateFirestoreWorkflowUpload } from '@/firebase/firestore/workflows';
import { PROJECT_AGREEMENT, PROJECT_PRODUCTION } from '@/router/projects';
import { PROJECT_UPLOAD_AGREEMENT, PROJECT_UPLOAD_QUOTATION } from '@/utils';
import DesignManagementDialog from '@/components/dialogs/DesignManagementDialog';
import { updateProjectName } from '@/firebase/firestore/projects';
import WorkflowDesignUnapproveDialog from '@/components/dialogs/WorkflowDesignUnapproveDialog';

export default {
    name: 'ProjectProgress',

    data: () => ({
        projectName: null,
        editProjectName: false,
        btnClasses: 'mr-3 mt-2 mb-1',
    }),

    components: {WorkflowDesignUnapproveDialog, DesignManagementDialog, UploadDialog, UserInviteAddDialog},

    filters: {
        stepIconColor: stepIconColor,
    },

    computed: {
        ...mapState('project', {
            project: 'boundInstance',
        }),
        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),
    },

    watch: {
        project (project) {
            this.projectName = project.name;
        },
    },

    methods: {
        saveAgreementUpload (uploads) {
            updateFirestoreWorkflowUpload(this.workflow, PROJECT_AGREEMENT, PROJECT_UPLOAD_AGREEMENT, uploads[0]);
        },

        releasePrototypeToArchitect () {
            return this.$releaseStep(this.PROJECT_PROTOTYPE)
                .finally(() => this.$store.dispatch('statusMessage/success', this.$t('workflow_prototype.released_to_architect')));
        },

        blockPrototypeForArchitect () {
            return this.$blockStep(this.PROJECT_PROTOTYPE)
                .finally(() => this.$store.dispatch('statusMessage/success', this.$t('workflow_prototype.revoked_from_architect')));
        },

        async saveQuotationUpload (uploads) {
            const isReleased = this.$isStepReleased(PROJECT_PRODUCTION);
            await updateFirestoreWorkflowUpload(this.workflow, PROJECT_PRODUCTION, PROJECT_UPLOAD_QUOTATION, uploads[0]);
            !isReleased && await this.$releaseStep(PROJECT_PRODUCTION);
        },

        tmpAdminResetWorkflow () {
            temporaryFunction_ResetFirestoreWorkflow(this.workflow.id, this.workflow.users);
        },

        updateProjectName () {
            updateProjectName(this.project.id, this.projectName).then(() => {
                this.$store.dispatch('statusMessage/success', 'The project name has been updated');
            }).catch(() => {
                this.$store.dispatch('statusMessage/error', 'There was an error while updating the project name');
            });
        },
    },
};
</script>

<style scoped lang="scss">
.project-step-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    &:hover {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
}

.v-data-table {
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
}
</style>
