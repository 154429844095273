import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import authentication from './authentication';
import imagePopup from './imagePopup';
import invites from './invites';
import locale from './locale';
import project from './project';
import projects from './projects';
import workflow from './workflow';
import statusMessage from './statusMessage';
import { ROLE_ARCHITECT, ROLE_DESIGNER, ROLE_SALES } from '@/utils';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: true,
    state: {
        navigationIsMini: false,
        photoDrawer: true,
        users: [
            {role: ROLE_ARCHITECT, displayName: 'Rem Koolhaas', uid: 'slGZIbxipkN5HjeT8TdmZHTG74b2', email: 'rem.koolhaas@zuiderlicht.nl', password: process.env.VUE_APP_ROLE_ARCHITECT_PASSWORD},
            {role: ROLE_DESIGNER, displayName: 'Gianmaria Della Ratta', uid: '4BzChPLIXiPRJPUDybERQNKQzBQ2', email: 'gianmaria.dellaratta@rodruza.nl', password: process.env.VUE_APP_ROLE_DESIGNER_PASSWORD},
            {role: ROLE_SALES, displayName: 'Jan Sales', uid: '5SOXtCaO2SZAFjxdSDysvoMijuL2', email: 'user.sales@rodruza.nl', password: process.env.VUE_APP_ROLE_SALES_PASSWORD},
        ],
    },

    getters: {
        _dev_getUsers: state => state.users,
        getPhotoDrawer: state => state.photoDrawer,
    },
    mutations: {
        SET_STATUS: function (state, value) {
            state.project.status = value;
        },
        SET_PROJECT: function (state, value) {
            state.project = value;
        },
        SET_NAVIGATION_MINI: function (state, value) {
            state.navigationIsMini = value;
        },
        SET_PHOTO_DRAWER: function (state, value) {
            state.photoDrawer = value;
        },
        SET_USERS: function (state, value) {
            state.users = value;
        },

        ...vuexfireMutations,
    },

    actions: {},

    modules: {
        authentication,
        imagePopup,
        invites,
        locale,
        project,
        projects,
        workflow,
        statusMessage,
    },
});

/**
 * Koppel het wijzigen van gebruiker (inlog/uitlog) aan acties in andere modules
 */
store.watch(state => state.authentication.user, val => {
    // Actieve project controleren (huidige gebruiker heeft misschien geen toegang)
    store.dispatch('project/updateProjectBindings');

    // Altijd projecten synchroniseren
    store.dispatch('projects/updateProjectsBindings');

    // Als de gebruiker inlogt controleren of deze een invite heeft gebruikt
    store.dispatch('invites/addUserToProject');
});

/**
 * Koppel het wijzigen van project aan updaten van de voortgang
 */
store.watch(state => state.project.boundInstance?.workflow, val => {
    store.dispatch('workflow/updateWorkflowBindings');
});

export default store;
