/**
 * This mixin should be used for elements that are toggleable
 * V-model should be used on the component to create two-way data binding
 * @example <component v-model="isVisible">
 */
export const toggleable = {
    data () {
        return {
            isVisible: !!this.value,
        };
    },
    props: {
        value: {
            required: false,
        }
    },
    watch: {
        value (newValue) {
            this.isVisible = !!newValue;
        },
        isVisible (newValue) {
            !!newValue !== this.value && this.$emit('input', newValue);
        }
    },
    methods: {
        cancel () {
            this.$emit('click:outside');
            this.isVisible = false;
        }
    }
};
