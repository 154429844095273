<template>
    <v-dialog v-model="isVisible" light max-width="650">
        <v-card>
            <v-card-title class="headline primary">{{ $t('image_full_dialog.image_detail') }}</v-card-title>
            <v-img :key="$store.getters['imagePopup/src']" :src="$store.getters['imagePopup/src']" :lazy-src="$store.getters['imagePopup/lazySrc']" :aspect-ratio="$store.getters['imagePopup/aspectRatio']" class="grey lighten-2 point">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="boxed-icon pr-0 mr-4 my-2" tiles elevation="0" @click="isVisible = false;">
                    {{ $t('global.close') }}
                    <v-icon right>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'TheImageFullDialog',

    computed: {
        isVisible: {
            set (value) {
                return this.$store.commit('imagePopup/SET_IS_VISIBLE', value);
            },
            get () {
                return this.$store.getters['imagePopup/isVisible'];
            }
        },
    },
};
</script>

<style scoped>

</style>
