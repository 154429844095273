import { PROJECT_AGREEMENT, PROJECT_DESIGN, PROJECT_WORKPLACE, PROJECT_PRODUCTION, PROJECT_PROTOTYPE, PROJECT_START } from '@/router/projects';
import { ucFirst } from '@/utils';
import { isStepCurrent } from '@/firebase/firestore/workflows';
import { userInGroupSuper } from '@/utils/authorization';

export default {
    data: () => ({
        NODE_ENV: process.env.NODE_ENV,
        PROJECT_START: PROJECT_START,
        PROJECT_AGREEMENT: PROJECT_AGREEMENT,
        PROJECT_WORKPLACE: PROJECT_WORKPLACE,
        PROJECT_DESIGN: PROJECT_DESIGN,
        PROJECT_PROTOTYPE: PROJECT_PROTOTYPE,
        PROJECT_PRODUCTION: PROJECT_PRODUCTION,
    }),

    props: {},

    watch: {},

    methods: {
        $isStepCurrent (name) {
            return isStepCurrent(this.$store.getters['workflow/progress'], name);
        },

        $isStepCompleted (name) {
            return this.$store.getters['workflow/completedOf' + ucFirst(name)];
        },

        $isStepEditable (name) {
            return this.$store.getters['workflow/editableOf' + ucFirst(name)];
        },

        $isStepReleased (name) {
            return this.$store.getters['workflow/releasedOf' + ucFirst(name)];
        },

        $isStepApproved (name) {
            return this.$store.getters['workflow/approvedOf' + ucFirst(name)];
        },

        /**
         * Keurt gegevens van een stap goed
         * @param name
         * @return {Promise<any>}
         */
        $approveStep (name) {
            return this.$store.dispatch('workflow/approveStep' + ucFirst(name));
        },

        /**
         * Maakt goedkeuring ongedaan voor een stap
         * @param name
         * @return {Promise<any>}
         */
        $unapproveStep (name) {
            return this.$store.dispatch('workflow/unapproveStep' + ucFirst(name));
        },

        /**
         * Geeft stap vrij
         * @param name
         * @return {Promise<any>}
         */
        $releaseStep (name) {
            return this.$store.dispatch('workflow/releaseStep' + ucFirst(name));
        },

        /**
         * Draai vrijgeven van stap terug
         * @param name
         * @return {Promise<any>}
         */
        $blockStep (name) {
            return this.$store.dispatch('workflow/blockStep' + ucFirst(name));
        },

        $gotoStep (nameOrId) {
            return this.$store.dispatch('workflow/gotoStep', nameOrId);
        },

        $gotoActiveStep () {
            return userInGroupSuper(this.$store.getters['authentication/getRoles'])
                ? this.$store.dispatch('workflow/gotoActiveStep')
                : this.$store.dispatch('workflow/gotoFirstUnapprovedStep');
        },

        $gotoFirstUnapprovedStep () {
            return this.$store.dispatch('workflow/gotoFirstUnapprovedStep');
        },
    }
};
