import { firestore } from 'firebase';
import { createFirestoreWorkflow } from '@/firebase/firestore/workflows';
import { createFirestoreMoodboard } from '@/firebase/firestore/projects/moodboards';
import { updateFirestoreForm } from '@/firebase/firestore/projects/forms';
import { FORM_AGREEMENT, FORM_START, MOODBOARD_PROTOTYPE, MOODBOARD_WORKPLACE } from '@/utils';

export const refProjects = firestore().collection('projects');

/**
 * Creeer template voor nieuw project
 * @param projectName
 * @param refMoodboard
 * @param refWorkflow
 * @returns {{workflow: *, name: *, users: []}}
 */
const createProject = ({projectName, refWorkflow, refWorkplace, refProto}) => ({
    name: projectName,
    users: [],
    workflow: refWorkflow,
    workplace: refWorkplace,
    proto: refProto,
    created: firestore.FieldValue.serverTimestamp(),
    updated: firestore.FieldValue.serverTimestamp(),
});

/**
 * Link een project aan een document
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refDocument
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refProject
 * @return {Promise<void>}
 */
const addFirestoreProjectReference = (refDocument, refProject) => refDocument.set({project: refProject}, {merge: true});

/**
 * Maak een project aan in Firestore en geef een referentie terug
 * @param projectName
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const createFirestoreProject = async ({projectName}) => {
    const refWorkflow = await createFirestoreWorkflow();
    const refProject = refProjects.doc();

    const refWorkplace = await createFirestoreMoodboard(refProject, MOODBOARD_WORKPLACE);
    const refProto = await createFirestoreMoodboard(refProject, MOODBOARD_PROTOTYPE);

    const project = createProject({projectName, refWorkflow, refWorkplace, refProto});
    await refProject.set(project);

    // Koppel het project ook aan moodboard en workflow voor bewerkingen later in het proces
    await addFirestoreProjectReference(refWorkplace, refProject);
    await addFirestoreProjectReference(refProto, refProject);
    await addFirestoreProjectReference(refWorkflow, refProject);

    // Voeg formulieren toe voor stappen 1 en 2
    await updateFirestoreForm({refProject, formId: FORM_START});
    await updateFirestoreForm({refProject, formId: FORM_AGREEMENT});

    return refProject;
};

/**
 * Update het project als het aangepast is of er een toevoeging wwordt gedaan
 * @param project
 * @return {Promise<void>}
 */
export const touchFirestoreProject = project => {
    let refProject = project;
    if (typeof project === 'string') {
        refProject = refProjects.doc(project.replace(/^projects\//, ''));
    }

    return refProject.update({updated: firestore.FieldValue.serverTimestamp()});
};

export const updateProjectName = async (projectId, name) => {
    const refProject = refProjects.doc(projectId);
    return refProject.update({name: name});
};

export const updateProjectAgreements = async (projectId, fileEntry) => {
    const refProject = refProjects.doc(projectId);

    const agreement = {
        timestamp: firestore.FieldValue.serverTimestamp(),
        ...fileEntry,
    };

    return refProject.collection('agreements').add(agreement);
};

export const updateProjectNda = async (projectId, fileEntry) => {
    const refProject = refProjects.doc(projectId);

    const nda = {
        timestamp: firestore.FieldValue.serverTimestamp(),
        ...fileEntry,
    };

    return refProject.collection('ndas').add(nda);
};

