import router from '@/router';
import { getProjectStepNumber, PROJECT_AGREEMENT, PROJECT_DESIGN, PROJECT_WORKPLACE, PROJECT_PRODUCTION, PROJECT_PROTOTYPE, PROJECT_START, PROJECT_STEPS_ORDER, ROUTER_PROJECTS } from '@/router/projects';
import { firestoreAction } from 'vuexfire';
import { refWorkflows } from '@/firebase';
import { approveStepBase, blockStepBase, getCompletedIndicatorBase, getEditableIndicatorBase, getProgressIndicatorBase, isAvailable, isStepApproved, isStepCurrent, isStepReleased, progressWorkflow, releaseStepBase, unapproveStepBase } from '@/firebase/firestore/workflows';
import { userInGroupArchitect, userInGroupSuper } from '@/utils/authorization';
import { ucFirst } from '@/utils';

const updateWorkflowBinding = async ({state, commit, rootGetters, bindFirestoreRef, unbindFirestoreRef}) => {
    const project = rootGetters['project/get'];
    const user = rootGetters['authentication/getUser'];
    const roles = rootGetters['authentication/getRoles'];

    let workflowId = null;

    if (!project) {
        commit('SET_INSTANCE_ID', null);
    } else {
        workflowId = typeof project.workflow === 'object' ? project.workflow.id : project.workflow;
        commit('SET_INSTANCE_ID', workflowId.replace(/^\/?workflows\//, ''));
    }

    if (user?.uid && state.boundInstanceId) {
        const refWorkflow = refWorkflows.doc(state.boundInstanceId);

        if (userInGroupSuper(roles)) {
            return bindFirestoreRef('boundInstance', refWorkflow);
        }

        const workflow = await refWorkflow.get();
        if (workflow.data().users.indexOf(user.uid) === -1) {
            return unbindFirestoreRef('boundInstance');
        }

        return bindFirestoreRef('boundInstance', refWorkflow);
    }

    return unbindFirestoreRef('boundInstance');
};

export default {
    namespaced: true,

    state: {
        step: 1,
        boundInstanceId: null,
        boundInstance: null,
    },

    getters: {
        get: state => state.boundInstance,

        progress: state => state.boundInstance?.progress,

        step: state => state.step,

        /**
         * Geeft voortgang van de betreffende stap
         * @param state
         * @return {number} - Nog niet bereikt: -1, Onvoltooid: 0, Voltooid: 1
         */
        progressOfStart: state => getProgressIndicatorBase(state.boundInstance, PROJECT_START),
        progressOfAgreement: state => getProgressIndicatorBase(state.boundInstance, PROJECT_AGREEMENT),
        progressOfWorkplace: state => getProgressIndicatorBase(state.boundInstance, PROJECT_WORKPLACE),
        progressOfDesign: state => getProgressIndicatorBase(state.boundInstance, PROJECT_DESIGN),
        progressOfPrototype: state => getProgressIndicatorBase(state.boundInstance, PROJECT_PROTOTYPE),
        progressOfProduction: state => getProgressIndicatorBase(state.boundInstance, PROJECT_PRODUCTION),

        /**
         * Is de stap vrij voor de architect?
         * Let op: als er geen vrijwaring nodig is is de stap automatisch vrij zodra het de huidige stap wordt
         * @param state
         * @return {*}
         */
        releasedOfStart: state => isStepReleased(state.boundInstance, PROJECT_START),
        releasedOfAgreement: state => isStepReleased(state.boundInstance, PROJECT_AGREEMENT),
        releasedOfWorkplace: state => isStepReleased(state.boundInstance, PROJECT_WORKPLACE),
        releasedOfDesign: state => isStepReleased(state.boundInstance, PROJECT_DESIGN),
        releasedOfPrototype: state => isStepReleased(state.boundInstance, PROJECT_PROTOTYPE),
        releasedOfProduction: state => isStepReleased(state.boundInstance, PROJECT_PRODUCTION),

        /**
         * Is de stap goedgekeurd door de architect?
         * Let op: als er geen goedkeuring nodig is is de stap automatisch goedgekeurd zodra het de huidige stap wordt
         * @param state
         * @return {*}
         */
        approvedOfStart: state => isStepApproved(state.boundInstance, PROJECT_START),
        approvedOfAgreement: state => isStepApproved(state.boundInstance, PROJECT_AGREEMENT),
        approvedOfWorkplace: state => isStepApproved(state.boundInstance, PROJECT_WORKPLACE),
        approvedOfDesign: state => isStepApproved(state.boundInstance, PROJECT_DESIGN),
        approvedOfPrototype: state => isStepApproved(state.boundInstance, PROJECT_PROTOTYPE),
        approvedOfProduction: state => isStepApproved(state.boundInstance, PROJECT_PRODUCTION),

        /**
         * Is de stap beschikbaar en vrijgegeven (released)
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @return {boolean}
         */
        editableOfStart: (state, getters, rootState, rootGetters) => {
            if (isStepApproved(state.boundInstance, PROJECT_START)) {
                return false;
            }

            if (userInGroupArchitect(rootGetters['authentication/getRoles']) && !isStepReleased(state.boundInstance, PROJECT_START)) {
                return false;
            }

            return getEditableIndicatorBase(state.boundInstance, PROJECT_START) > 0;
        },
        editableOfAgreement: (state, getters, rootState, rootGetters) => {
            if (isStepApproved(state.boundInstance, PROJECT_AGREEMENT)) {
                return false;
            }

            if (userInGroupArchitect(rootGetters['authentication/getRoles'])) {
                return false;
            }

            return getEditableIndicatorBase(state.boundInstance, PROJECT_AGREEMENT) > 0;
        },
        editableOfWorkplace: state => getEditableIndicatorBase(state.boundInstance, PROJECT_WORKPLACE) > 0,
        editableOfDesign: state => getEditableIndicatorBase(state.boundInstance, PROJECT_DESIGN) > 0,
        editableOfPrototype: state => getEditableIndicatorBase(state.boundInstance, PROJECT_PROTOTYPE) > 0,
        editableOfProduction: state => getEditableIndicatorBase(state.boundInstance, PROJECT_PRODUCTION) > 0,

        /**
         * Is deze stap huidig of gepasseerd en goedgekeurd?
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @return {boolean}
         */
        completedOfStart: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_START, rootGetters) > 0,
        completedOfAgreement: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_AGREEMENT, rootGetters) > 0,
        completedOfWorkplace: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_WORKPLACE, rootGetters) > 0,
        completedOfDesign: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_DESIGN, rootGetters) > 0,
        completedOfPrototype: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_PROTOTYPE, rootGetters) > 0,
        completedOfProduction: (state, getters, rootState, rootGetters) => getCompletedIndicatorBase(state.boundInstance, PROJECT_PRODUCTION, rootGetters) > 0,
    },

    mutations: {
        SET_INSTANCE_ID: function (state, value) {
            state.boundInstanceId = value;
        },
        SET_STEP (state, step) {
            state.step = step;
        },
    },

    actions: {
        /**
         * Ga naar stap via de router.
         * Deze functie controleert eerst dat de gevraagd doorverwijzing geldig is en maakt
         * vervolgens de URL op en stuurt daar naartoe door.
         *
         * Let op: Deze action initieert geen mutaties. De procedure via de router handelt de staat
         *         van de store af en bepaalt of een route bereikbaar is.
         *
         * @param dispatch
         * @param rootState
         * @param {Number|String} nameOrNr - Naam of nummer van de gevraagde stap
         */
        gotoStep ({dispatch, rootState}, nameOrNr) {
            const projectId = rootState.project?.boundInstanceId;

            // Naam/nummer en een actief project zijn vereist
            if (!nameOrNr || !projectId) {
                return;
            }

            if (typeof nameOrNr === 'string' && !nameOrNr.match(/^\d+$/) && !ROUTER_PROJECTS.find(route => route.name === nameOrNr)) {
                // nameOrNr is niet een bekende stap
                return;
            }

            let name = nameOrNr;

            // Test of nameOrNr numeriek is (nummer van stap)
            if (typeof nameOrNr === 'number' || nameOrNr.match(/^\d+$/)) {
                const stepId = parseInt(nameOrNr);
                const routerStep = ROUTER_PROJECTS.find(route => route.meta.step === stepId);
                name = routerStep.name;
            }

            router.push({name, params: {projectId}});
        },

        /**
         * Ga naar de actieve stap via de router
         *
         * Let op: Deze action initieert geen mutaties. De procedure via de router handelt de staat
         *         van de store af en bepaalt of een route bereikbaar is.
         *
         * @param state
         * @param dispatch
         */
        gotoActiveStep ({state, dispatch}) {
            dispatch('gotoStep', state.boundInstance?.progress);
        },

        gotoFirstUnapprovedStep ({state, dispatch}) {
            if (!state.boundInstance) {
                return;
            }

            const workflow = state.boundInstance;
            for (let step of PROJECT_STEPS_ORDER) {
                if (isStepCurrent(state.boundInstance.progress, step)) {
                    return dispatch('gotoStep', state.boundInstance.progress);
                }

                if (typeof workflow.steps[step].approved !== 'undefined' && !workflow.steps[step].approved) {
                    return dispatch('gotoStep', step);
                }
            }
        },

        activateStart ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_START));
        },
        activateAgreement ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_AGREEMENT));
        },
        activateWorkplace ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_WORKPLACE));
        },
        activateDesign ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_DESIGN));
        },
        activatePrototype ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_PROTOTYPE));
        },
        activateProduction ({commit}) {
            commit('SET_STEP', getProjectStepNumber(PROJECT_PRODUCTION));
        },

        /**
         * APPROVE = Keurt gegevens van een stap goed
         * Dit is de inhoudelijke goedkeuring (door klant)
         */
        approveStepStart: ({state, commit, dispatch}) => approveStepBase(state.boundInstance, PROJECT_START)
            .finally(() => dispatch('gotoActiveStep')),
        approveStepAgreement: ({state, dispatch}) => approveStepBase(state.boundInstance, PROJECT_AGREEMENT)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_AGREEMENT))
            .finally(() => dispatch('gotoActiveStep')),
        approveStepWorkplace: ({state}) => approveStepBase(state.boundInstance, PROJECT_WORKPLACE),
        approveStepDesign: ({state}) => approveStepBase(state.boundInstance, PROJECT_DESIGN)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_DESIGN)),
        approveStepPrototype: ({state}) => approveStepBase(state.boundInstance, PROJECT_PROTOTYPE),
        approveStepProduction: ({state}) => approveStepBase(state.boundInstance, PROJECT_PRODUCTION),

        /**
         * UNAPPROVE = Trek goedkeuring in
         * @param state
         * @param dispatch
         * @return {Promise<void>}
         */
        unapproveStepDesign: ({state, dispatch}) => unapproveStepBase(state.boundInstance, PROJECT_DESIGN)
            // Deze stap kan vrijgegeven zijn, maar dat kan niet als het proces terug gaat naar design
            .finally(() => dispatch('blockStepPrototype'))
            .finally(() => dispatch('gotoFirstUnapprovedStep')),

        /**
         * RELEASE = Geeft stap vrij en markeert volgende als actief
         * Let op: muteert de store niet, maar spreekt direct Firebase aan die vervolgens de store bijwerkt
         * @param state
         * @param commit
         * @param dispatch
         */
        releaseStepStart: ({state, commit, dispatch}) => releaseStepBase(state.boundInstance, PROJECT_START)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_START))
            .then(() => dispatch('gotoActiveStep'))
            .catch(() => dispatch('gotoActiveStep')),
        releaseStepAgreement: ({state}) => releaseStepBase(state.boundInstance, PROJECT_AGREEMENT),
        releaseStepWorkplace: ({state, dispatch}) => releaseStepBase(state.boundInstance, PROJECT_WORKPLACE)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_WORKPLACE))
            .finally(() => dispatch('gotoActiveStep')),
        releaseStepDesign: ({state, dispatch}) => releaseStepBase(state.boundInstance, PROJECT_DESIGN)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_DESIGN))
            .finally(() => dispatch('gotoActiveStep')),
        releaseStepPrototype: ({state, dispatch}) => releaseStepBase(state.boundInstance, PROJECT_PROTOTYPE)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_PROTOTYPE)),
        releaseStepProduction: ({state, dispatch}) => releaseStepBase(state.boundInstance, PROJECT_PRODUCTION)
            .then(() => progressWorkflow(state.boundInstance, PROJECT_PRODUCTION))
            .finally(() => dispatch('gotoActiveStep')),

        /**
         * BLOCK = Maak vrijgeven van een stap ongedaan
         * @param state
         * @return {Promise<void>}
         */
        blockStepPrototype: ({state}) => blockStepBase(state.boundInstance, PROJECT_PROTOTYPE),

        /**
         * Start met luisteren naar route binnen de context van deze store
         * Let op: mag maar 1 keer aangeroepen worden!!
         * @param state
         * @param dispatch
         * @param getters
         */
        initialize ({state, dispatch, getters}) {
            router.beforeEach((to, from, next) => {
                if (to.meta.dispatch && to.meta.step && to.meta.step !== state.step) {
                    if (isAvailable(getters['progressOf' + ucFirst(to.name)])) {
                        dispatch(to.meta.dispatch, void (0), {root: true});
                        next();
                    } else {
                        next(false);
                    }
                } else {
                    next();
                }
            });
        },

        /**
         * Koppel de actieve workflow uit Firebase aan state.boundInstance
         */
        updateWorkflowBindings: firestoreAction(async context => await updateWorkflowBinding(context)),
    }
};
