<template>
    <v-simple-table height="100%">
        <template #default>
            <thead>
            <tr>
                <th class="text-left">{{ $t('project.name_short') }}</th>
                <th class="text-right">{{ $t('project.option_actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="project in projects" :key="project.id" @click="setProject(project)" :class="project.id === boundProjectId ? '-active' : ''">
                <td class="tiemposText tiemposText--bold text-left">{{ project.name }}</td>
                <td>
                    <v-btn :outlined="project.id !== boundProjectId" small tile color="primary" class="float-right boxed-icon">
                        {{ $t('global.open') }}
                        <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ProjectListTabular',

    created () {
        this.$store.dispatch('projects/addProjectsViewer');
    },

    beforeDestroy () {
        this.$store.dispatch('projects/removeProjectsViewer');
    },

    computed: {
        ...mapState('project', {
            boundProjectId: 'boundInstanceId',
        }),
        ...mapState('projects', {
            projects: 'boundQueryResults',
        })
    },

    methods: {
        setProject (project) {
            this.$emit('click', project);
        },
    },
};
</script>

<style scoped>

</style>
