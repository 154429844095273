import { updateUserDisplayName } from '@/firebase/authentication/user';
import { refMoodboards } from '@/firebase/firestore/projects/moodboards';
import { refProjects } from '@/firebase/firestore/projects';
import { refWorkflows } from '@/firebase/firestore/workflows';

/**
 * Genereer een unieke ID zoals Firebase dit ook doet
 * Let op: Laat waar mogelijk Firebase zelf ID's genereren. Bij opschalen van applicaties geeft dit anders mogelijk problemen.
 * @return {string}
 */
const newId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return autoId;
};

export {
    updateUserDisplayName,
    refMoodboards,
    refProjects,
    refWorkflows,
    newId,
};
