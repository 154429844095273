import './firebase/initializeApp';
import Vue from 'vue';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
import './plugins/vuefire';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import authorization from '@/mixins/authorization';
import project from '@/mixins/project';
import resources from '@/mixins/resources';

// Globale mixins
Vue.mixin(authorization);
Vue.mixin(project);
Vue.mixin(resources);

// Stel correcte taal in voor huidige gebruiker
router.beforeEach(async (to, from, next) => {
    if (!store.state.locale.language) {
        await store.dispatch('locale/setLanguage', navigator.languages);
    } else if (store.state.locale.language !== i18n.locale) {
        i18n.locale = store.state.locale.language;
    }

    next();
});

// Laad project in als een id in de URL is geplaatst
router.beforeEach(async (to, from, next) => {
    if (to.params.projectId && to.params.projectId !== store.state.project.boundInstanceId) {
        await store.dispatch('project/setProject', to.params.projectId);
    }

    next();
});

// Configuratie
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
