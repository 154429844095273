export default {
    namespaced: true,

    state: {
        message: '',
        isVisible: false,
        status: 'info', // succces, info, error,
    },

    getters: {
        message: state => state.message,
        isVisible: state => state.isVisible,
        status: state => state.status,
    },

    mutations: {
        SET_MESSAGE (state, message) {
            state.message = message;
        },
        SET_IS_VISIBLE (state, isVisible) {
            state.isVisible = isVisible;
        },
        SET_STATUS (state, status) {
            state.status = status;
        }
    },

    actions: {
        success ({commit}, message) {
            commit('SET_MESSAGE', message);
            commit('SET_STATUS', 'success');
            commit('SET_IS_VISIBLE', true);
        },
        error ({commit}, message) {
            commit('SET_MESSAGE', message);
            commit('SET_STATUS', 'error');
            commit('SET_IS_VISIBLE', true);
        },
        info ({commit}, message) {
            commit('SET_MESSAGE', message);
            commit('SET_STATUS', 'info');
            commit('SET_IS_VISIBLE', true);
        },
    }
};
