import { storage } from 'firebase';
import { canvasBlob, canvasBlur, canvasClone, imageScale } from '@/utils/image';
import { IMPORT_IMAGE_MAX_DIMENSIONS, isImageType } from '@/utils';
import { newId } from '@/firebase';

/**
 * Maakt een object aan dat gebruikt kan worden als item in Firestore met verwijzing naar objecten als URL
 * @param {String} title
 * @param {String} url
 * @param {String} thumb
 * @param {String} lazy - Url naar placeholder tijdens lazy loading
 * @param {String} type - mimetype mits beschikbaar
 * @param {Object} meta
 * @return {{thumb: String, title: String, url: String}}
 */
export const createFileEntry = ({title, url, thumb = '', lazy = '', type = '', meta = null}) => {
    const entry = {
        title,
        type,
        url,
    };

    if (thumb) {
        entry.thumb = thumb;
    }

    if (lazy) {
        entry.lazy = lazy;
    }

    if (meta) {
        entry.meta = meta;
    }

    return entry;
};

/**
 * Herken of een object een fileEntry is (via duck typing)
 * @param subject
 * @return {boolean}
 */
export const isFileEntry = subject => typeof subject.title === 'string' && typeof subject.type === 'string' && typeof subject.url === 'string';

/**
 * Upload een bestand naar filePath binnen Storage.
 * @param filePath - Klassiek bestandspad binnen storage
 * @param file - Bestandsobject
 * @return {firebase.storage.UploadTask} - Thennable UploadTask
 */
const uploadFile = (filePath, file) => storage().ref(filePath).put(file);

/**
 * Upload bestand en geef de publieke URL terug
 * @param filePath - Klassiek bestandspad binnen storage
 * @param file - Bestandsobject
 * @return {Promise<String>}
 */
export const uploadFileAndGetDownloadUrl = (filePath, file) => uploadFile(filePath, file).then(fileSnapshot => fileSnapshot.ref.getDownloadURL());

/**
 * Voeg "-thumb" toe aan bestandspad, direct voor de extensie
 * @param {String} filePath
 * @return {String}
 */
const addThumbNameToFilePath = filePath => filePath.replace(/(\.[a-zA-Z0-9]+)$/, '-thumb$1');
const addLazyNameToFilePath = filePath => filePath.replace(/(\.[a-zA-Z0-9]+)$/, '-lazy$1');
const addRandomHashToFilePath = filePath => filePath.replace(/(\.[a-zA-Z0-9]+)$/, '-' + newId().substr(-7) + '$1');
export const changeFilePathExtension = (filePath, extension) => filePath.replace(/\.[a-zA-Z0-9]+$/, '.' + extension);

/**
 * Upload bestand en geeft een object terug voor opslag data in Firestore
 * @param filePath - Klassiek bestandspad binnen storage
 * @param {File|Blob} file - Bestandsobject
 * @param {HTMLCanvasElement|null} resized - Bestandsobject van de upload indien het bestaand te groot was. Indien ontbreekt wordt originele bestand gebruikt.
 * @param {HTMLCanvasElement|null} thumb - Bestandsobject
 * @param {HTMLCanvasElement|null} lazy - Bestandsobject voor lazy src als placeholder
 * @param title - Optionele titel van het bestand i.p.v. de bestandsnaam
 * @param type - Mimetype mits beschikbaar, overschrijft mimetype uit file
 * @return {Promise<Object>}
 */
const uploadFileAndGetFileEntry = async (filePath, file, {resized = null, thumb = null, lazy = null, title = null, type = null} = {thumb: null, lazy: null, title: null, type: null}) => {
    const url = await uploadFileAndGetDownloadUrl(filePath, resized || file);

    let thumbUrl = '';
    if (thumb) {
        const blobThumb = await canvasBlob(thumb, 'image/jpeg');
        const thumbPath = changeFilePathExtension(addThumbNameToFilePath(filePath), 'jpg');
        thumbUrl = await uploadFileAndGetDownloadUrl(thumbPath, blobThumb);
    }

    let lazyUrl = '';
    if (lazy) {
        const blobLazy = await canvasBlob(lazy, 'image/jpeg', 0.4);
        const lazyPath = changeFilePathExtension(addLazyNameToFilePath(filePath), 'jpg');
        lazyUrl = await uploadFileAndGetDownloadUrl(lazyPath, blobLazy);
    }

    return createFileEntry({
        url,
        type: type || file.type,
        title: title || file.name,
        thumb: thumbUrl,
        lazy: lazyUrl,
        meta: file.meta,
    });
};

/**
 * Haal bestandsgegevens op, maak thumbnail en initieer uploads
 * @param {String} folderPath
 * @param {Object[]} files
 * @param {Boolean} createLazy - Creeer een lazy-loading preview (default is true)
 * @param {Boolean} createThumb - Creeer een thumbnail van afbeelding (default is true)
 * @param {Number} thumbSize - Afmeting van thumbnail (default is 200)
 * @return {Promise<[]>}
 */
export const uploadFiles = async (folderPath, files, {createLazy = true, createThumb = true, thumbSize = 200} = {createLazy: true, createThumb: true, thumbSize: 200}) => {
    const entryFiles = [];

    // Geen bestanden aangeleverd dan geen verdere actie ondernemen
    if (!files?.length) {
        return entryFiles;
    }

    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Sla verwerking over als een file-entry is meegegeven
        if (isFileEntry(file)) {
            entryFiles.push(file);
            continue;
        }

        // Voorkomt dubbele bestandsnamen
        const fileName = addRandomHashToFilePath(file.name);
        let filePath = folderPath.replace(/(^\/|\/$)/g, '') + '/' + fileName;

        let resized = null;
        let thumb = null;
        let lazy = null;
        if (isImageType(file.type)) {
            resized = await imageScale(file, {maxWidth: IMPORT_IMAGE_MAX_DIMENSIONS, maxHeight: IMPORT_IMAGE_MAX_DIMENSIONS});
            if (resized.width === IMPORT_IMAGE_MAX_DIMENSIONS || resized.height === IMPORT_IMAGE_MAX_DIMENSIONS) {
                resized = await canvasBlob(resized, 'image/jpeg');
                filePath = changeFilePathExtension(filePath, 'jpg');
            } else {
                resized = null;
            }

            if (createThumb) {
                thumb = await imageScale(file, {maxWidth: thumbSize});
            }

            if (createLazy) {
                lazy = canvasClone(thumb);
                await canvasBlur(lazy, 20);
            }
        }

        const fileEntry = await uploadFileAndGetFileEntry(filePath, file, {resized, thumb, lazy});
        entryFiles.push(fileEntry);
    }

    return entryFiles;
};

/**
 * Verwijder een map met alle bestanden. Als deze map ook submappen bevat dan
 * aanroepen met recursive = true.
 * @param {String} path - Pad naar deze map
 * @param {Boolean} recursive - Verwijder ook submappen. Standaard false als beveliging voor te veel verwijderen.
 * @return {Promise<void>}
 */
export const deleteFolder = async (path, recursive = false) => {
    const refPath = storage().ref(path);
    const dir = await refPath.listAll();

    if (!recursive && dir.prefixes.length) {
        throw 'Folder contains subfolders, please call this function with recursive set to true to remove subfolders.';
    }

    dir.items.forEach(refFile => {
        deleteFile(refPath.fullPath, refFile.name);
    });

    dir.prefixes.forEach(refFolder => {
        deleteFolder(refFolder.fullPath);
    });
};

export const deleteFile = (path, fileName) => {
    const refPath = storage().ref(path);
    const refFile = refPath.child(fileName);
    refFile.delete();
};
