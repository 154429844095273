<template>
    <v-dialog v-model="showDialog" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" class="float-right mr-4 boxed-icon" tile small elevation="0" :disabled="disabled">
                {{ $t('global.create') }}
                <v-icon right>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('new_project_dialog.create_project') }}</v-card-title>
            <v-card-text class="mt-4">
                <v-text-field v-model="projectName" :label="$t('project.name')" required></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showDialog = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn @click="createProject" color="primary" type="submit" class="boxed-icon pr-0 mr-4" tiles elevation="0">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ProjectAddDialog',

    props: {
        disabled: Boolean,
    },

    data: () => ({
        projectName: '',
        showDialog: false,
    }),

    methods: {
        resetForm () {
            this.projectName = '';
        },

        createProject () {
            this.$store.dispatch('project/createProject', {
                projectName: this.projectName,
            });

            this.resetForm();
            this.showDialog = false;
        },
    },
};
</script>

<style scoped>

</style>
