import { formdataClient } from '@/services';
import i18n from '@/plugins/i18n';

export default {
    namespaced: true,

    state: {
        activeInviteId: null,
    },

    getters: {},

    mutations: {
        SET_INVITE_ID: function (state, payload) {
            state.activeInviteId = payload;
        },
    },

    actions: {
        /**
         * Stel in welke invite de gebruiker gebruikt
         * @param commit
         * @param payload
         */
        setInviteId ({commit}, payload) {
            commit('SET_INVITE_ID', payload);
        },

        /**
         * Voeg de gebruiker toe aan het project beschreven in de invite
         * @param commit
         * @param dispatch
         * @param state
         * @param rootState
         * @param payload
         */
        addUserToProject ({commit, dispatch, state, rootState}, payload) {
            // Overslaan als geen invite werd geopend
            if (!state.activeInviteId) {
                return;
            }

            return formdataClient
                .post(process.env.VUE_APP_FIREBASE_FUNCTIONS_RESOURCE_USEINVITE + '/' + state.activeInviteId, {
                    'userId': rootState.authentication.user.uid,
                })
                .then(() => dispatch('statusMessage/success', i18n.t('invite.user_linked_to_project'), {root: true}))
                .catch(error => console.error(error))
                .finally(() => commit('SET_INVITE_ID', null));

        },
    },
};
