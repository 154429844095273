/**
 * Bekijkt of date een Datum-object is
 * @param {*} date
 * @return {Boolean}
 */
const isDate = date => date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);

/**
 * Converteer input naar Date-object
 * @param {Date|Object|String} input - Een Date-object blijft ongewijzigd, een object wordt
 * @return {Date|Object}
 */
export const toDate = input => isDate(input) ? input : (typeof input.toDate === 'function' ? input.toDate() : (typeof input === 'string' ? new Date(input) : input));
