import { firestore } from 'firebase';

/**
 * Maak een template voor een formulier. Data bevat ingevulde formulierwaardes, maar is initieel leeg
 * @return {{data: {}, modified: firebase.firestore.FieldValue, users: []}}
 */
export const createForm = (data = {}, users = []) => ({
    data,
    users,
    modified: firestore.FieldValue.serverTimestamp(),
});

const getFormReferenceByDocumentPath = documentPath => firestore().doc(documentPath);
const getFormReferenceByFormId = (refProject, formId) => {
    const collectionForms = refProject.collection('forms');
    return collectionForms.doc(formId);
};

/**
 * Maak een formulier aan in Firestore en geef een referentie terug
 * @param {DocumentReference<DocumentData>} refProject
 * @param {String} formId
 * @param {String} documentPath - Optioneel volledig pad naar document
 * @param {Object} data - Inhoud van formulier
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const updateFirestoreForm = async ({refProject, formId, documentPath, data}) => {
    if ((!refProject || !formId) && !documentPath) {
        return Promise.reject('Missing refProject and formId or full documentPath');
    }

    const refForm = refProject && formId
        ? getFormReferenceByFormId(refProject, formId)
        : getFormReferenceByDocumentPath(documentPath);

    await refForm.set(data || createForm());

    return refForm;
};
