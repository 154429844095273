<template>
    <v-dialog v-model="isVisible" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" class="boxed-icon" tile elevation="0">
                    {{ $t('project.ui.add_comment') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ computedHeadline }}</v-card-title>
            <v-card-text v-if="explanation" class="tiemposText mt-5">{{ explanation }}</v-card-text>
            <v-form ref="form" v-model="formValid" class="ma-4">
                <v-textarea :placeholder="$t('add_comment_dialog.comment_placeholder', [context])" v-model="comment" :rules="ruleNotEmpty"/>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn :disabled="!formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="addComment">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';
import { RULES_FIELD_REQUIRED } from '@/utils';
import { createComment, createFirestoreComment } from '@/firebase/firestore/projects/comments';
import { userInGroupArchitect } from '@/utils/authorization';

export default {
    name: 'CommentAddDialog',

    mixins: [toggleable],

    props: {
        headline: {
            type: String,
            default: '',
        },
        explanation: {
            type: String,
            default: '',
        },
        context: {
            type: String,
            default: '',
        },
        project: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        roles: {
            type: Array,
            default: [],
        },
    },

    watch: {
        values () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },
    },

    computed: {
        computedHeadline () {
            return (this.headline || this.$t('add_comment_dialog.headline')) + ' ' + this.context;
        },
    },

    data: () => ({
        ruleNotEmpty: [...RULES_FIELD_REQUIRED],
        formValid: null,
        comment: '',
    }),

    methods: {
        resetForm () {
            this.comment = '';
        },

        addComment () {
            const text = this.comment.trim();

            if (text === '') {
                return this.$store.dispatch('statusMessage/info', this.$t('comments.no_empty_comment_status_message'));
            }

            const comment = createComment({
                context: this.context,
                ctxLink: null,
                text,
                user: this.user,
                project: this.project,
                emphasized: userInGroupArchitect(this.roles),
            });

            createFirestoreComment(this.project.$ref, comment)
                .then(() => this.$store.dispatch('statusMessage/success', this.$t('comments.new_comment_added')));

            this.resetForm();

            this.isVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">

</style>
