export const PROJECT_START = 'start';
export const PROJECT_AGREEMENT = 'agreement';
export const PROJECT_WORKPLACE = 'workplace';
export const PROJECT_DESIGN = 'design';
export const PROJECT_PROTOTYPE = 'prototype';
export const PROJECT_PRODUCTION = 'production';

export const PROJECT_STEPS_ORDER = [
    PROJECT_START,
    PROJECT_AGREEMENT,
    PROJECT_WORKPLACE,
    PROJECT_DESIGN,
    PROJECT_PROTOTYPE,
    PROJECT_PRODUCTION,
];

export const getProjectStepNumber = name => PROJECT_STEPS_ORDER.indexOf(name) + 1;

export const getNextStepName = name => {
    const nextStep = PROJECT_STEPS_ORDER.indexOf(name) + 1;

    if (nextStep > PROJECT_STEPS_ORDER.length) {
        return false;
    }

    return PROJECT_STEPS_ORDER[nextStep];
};

export const ROUTER_PROJECTS = [
    {
        // Maak voor /projects/../ een redirect naar /projects/../start
        path: '',
        redirect: 'start',
        meta: {step: 0}
    },
    {
        path: 'start',
        name: PROJECT_START,
        component: () => import(/* webpackChunkName: "project-start" */ '../views/workflow/TheProjectStart.vue'),
        meta: {step: getProjectStepNumber(PROJECT_START), dispatch: 'workflow/activateStart'},
    },
    {
        path: 'agreement',
        name: PROJECT_AGREEMENT,
        component: () => import(/* webpackChunkName: "project-agreement" */ '../views/workflow/TheProjectAgreement.vue'),
        meta: {step: getProjectStepNumber(PROJECT_AGREEMENT), dispatch: 'workflow/activateAgreement'},
    },
    {
        path: 'virtual-workplace',
        name: PROJECT_WORKPLACE,
        component: () => import(/* webpackChunkName: "project-workplace" */ '../views/workflow/TheProjectWorkplace.vue'),
        meta: {step: getProjectStepNumber(PROJECT_WORKPLACE), dispatch: 'workflow/activateWorkplace'},
    },
    {
        path: 'design',
        name: PROJECT_DESIGN,
        component: () => import(/* webpackChunkName: "project-designer" */ '../views/workflow/TheProjectDesigner.vue'),
        meta: {step: getProjectStepNumber(PROJECT_DESIGN), dispatch: 'workflow/activateDesign'},
    },
    {
        path: 'prototype',
        name: PROJECT_PROTOTYPE,
        component: () => import(/* webpackChunkName: "project-prototype" */ '../views/workflow/TheProjectPrototype.vue'),
        meta: {step: getProjectStepNumber(PROJECT_PROTOTYPE), dispatch: 'workflow/activatePrototype'},
    },
    {
        path: 'quotation-and-production',
        name: PROJECT_PRODUCTION,
        component: () => import(/* webpackChunkName: "project-production" */ '../views/workflow/TheProjectProduction.vue'),
        meta: {step: getProjectStepNumber(PROJECT_PRODUCTION), dispatch: 'workflow/activateProduction'},
    },
];
