<template>
    <v-dialog v-model="isVisible" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" class="boxed-icon" tile elevation="0">
                    {{ $t('workflow_design.manage') }}
                    <v-icon right>mdi-pencil-outline</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('workflow_design.manage_headline') }}</v-card-title>
            <design-list-tabular class="mx-2 mt-2 mb-4" :key="(designs.length ? designs[0].id : '0')+approvedDesign" :designs="designs" :append-column="$t('workflow_design.design_actions')" prepend-column="">
                <template #prepend="{design}">
                    <v-icon v-if="approvedDesign === design.$snap.ref.path" :title="$t('workflow_design.approved_design')">mdi-check</v-icon>
                </template>
                <template #append="{design}">
                    <v-btn :color="design.visible ? 'primary' : 'grey'" circle icon tile elevation="0" @click="setVisibility(design.$snap.ref, !design.visible)">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <delete-confirm-dialog v-if="design.$snap.ref" :heading="$t('design_delete.headline')" @delete="removeDesign(design.$snap.ref)">
                        <template #default="{ on, attrs }">
                            <span :title="design.deletable ? false : $t('design_delete.not_deletable')">
                                <v-btn v-bind="attrs" v-on="on" :disabled="!design.deletable" color="primary" circle icon tile elevation="0">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <template #body>{{ $t('design_delete.explanation', [design.name]) }}</template>
                    </delete-confirm-dialog>
                </template>
            </design-list-tabular>
            <v-card-actions>
                <book-navigation
                    :has-previous="designs.length && hasPrev" @previous="prevPage(designs[0])"
                    :has-next="designs.length && hasNext" @next="nextPage(designs[designs.length - 1])"
                />
                <v-spacer/>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.close') }}</v-btn>
                <design-add-dialog v-slot="{ on, attrs }" :project="project" :user="user" @added="onNewDesignAdded">
                    <v-btn v-bind="attrs" v-on="on" :disabled="!project.$ref" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0">
                        {{ $t('global.create') }}
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </design-add-dialog>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import { mapGetters } from 'vuex';
import { toggleable } from '@/mixins/toggleable';
import DesignListTabular from '@/components/DesignListTabular';
import { userInGroupSuper } from '@/utils/authorization';
import DesignAddDialog from '@/components/dialogs/DesignAddDialog';
import { PROJECT_DESIGN, PROJECT_WORKPLACE } from '@/router/projects';
import BookNavigation from '@/components/BookNavigation';
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirmDialog';
import { removeFirestoreDesignAndRemoveFiles, updateFirestoreDesign } from '@/firebase/firestore/projects/designs';
import { getEditableIndicatorBase, progressWorkflow } from '@/firebase/firestore/workflows';

export default {
    name: 'DesignManagementDialog',

    components: {DeleteConfirmDialog, BookNavigation, DesignAddDialog, DesignListTabular},

    mixins: [toggleable],

    props: {
        project: {
            type: Object,
            required: true,
        },
        workflow: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        pageSize: 12,
        firstDesign: null,
        designs: [],
        approvedDesign: '',
        hasPrev: false,
        hasNext: false,
    }),

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),
    },

    watch: {
        isVisible (value) {
            value && this.loadDesigns();
        },

        workflow (workflow) {
            this.approvedDesign = workflow.steps[PROJECT_DESIGN].design || '';
        },
    },

    mounted () {
        this.approvedDesign = this.workflow?.steps[PROJECT_DESIGN].design || '';

        /** @var {Object} - Bevat de Firestore Query template voor paginering van de designs */
        this.designsPageTemplate = null;

        /** @var {Object} - Bevat de Firestore Query (inclusief cursor en limiet) voor paginering van de designs */
        this.designsPage = null;

        this.initiateDesigns();
    },

    methods: {
        prevPage (first) {
            this.designsPage = this.designsPageTemplate.endAt(first.$snap).limitToLast(this.pageSize + 1);
            this.loadDesigns();
        },

        nextPage (last) {
            // Neem 1 item extra (die niet getoond wordt).
            // Als er pageSize plus 1 items in de resultaten zitten is er nog
            // een volgende pagina.
            this.designsPage = this.designsPageTemplate.startAfter(last.$snap).limit(this.pageSize + 1);
            this.loadDesigns();
        },

        resetSearch () {
            this.firstDesign = null;
            this.designsPage = this.designsPageTemplate;
            this.loadDesigns();
        },

        /**
         * Controleer na een nieuw ontwerp of de designstap al vrij is gegeven en doe dit indien dat nog niet is gebeurd
         */
        onNewDesignAdded () {
            // Kijk of de stap al beschikbaar is (heet editable in het systeem)
            if (!getEditableIndicatorBase(this.workflow, PROJECT_DESIGN)) {
                progressWorkflow(this.workflow, PROJECT_WORKPLACE);
            }

            this.resetSearch();
        },

        /**
         * Haal 1 pagina op met resultaten (na scrollen)
         * @return {Promise<void>}
         */
        async loadDesigns () {
            const documentSnapshots = await this.designsPage.get();
            this.designs = documentSnapshots.docs.map(design => design);

            // Eeste keer het eerste item onthouden
            // Dit dient voor paginering (eerste pagina herkennen)
            if (!this.firstDesign && this.designs.length) {
                this.firstDesign = this.designs[0];
            }

            this.hasPrev = this.designs.length && this.firstDesign?.id !== this.designs[0].id;
            this.hasNext = this.designs.length > this.pageSize;

            // Verwijder laatste item als het enkel voor paginering was opgehaald
            if (this.hasNext) {
                this.designs.pop();
            }

            // Maak designs op voor weergave
            this.designs = this.designs.map(design => ({...design.data(), id: design.id, $snap: design}));
        },

        /**
         * Stel objecten in voor infinite scroll & live update
         * @return {Promise<void>}
         */
        initiateDesigns () {
            const refProject = this.project.$ref;

            let collectionDesigns = refProject.collection('designs');

            if (!userInGroupSuper(this.roles)) {
                collectionDesigns = collectionDesigns.where('users', 'array-contains', this.user.uid);
            }

            // Stel de query in voor het ophalen van 1 "pagina" aan designs
            this.designsPageTemplate = collectionDesigns
                .orderBy('timestamp', 'desc')
                .limit(this.pageSize + 1);

            this.designsPage = this.designsPageTemplate;
        },

        /**
         * Verwijder het design + alle bestanden
         * @param refDesign
         * @return {Promise<void>}
         */
        async removeDesign (refDesign) {
            await removeFirestoreDesignAndRemoveFiles(refDesign);
            this.resetSearch();
        },

        /**
         * Wijzig de zichtbaarheid van dit design. Het wordt daardoor niet
         * getoond in de lijst met designs. Indien het design al eens
         * geaccepteerd is, is het design wel zichtbaar in de prototypefase.
         * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refDesign
         * @param {Boolean} visible - Stel design in als (on)zichtbar
         */
        setVisibility (refDesign, visible) {
            updateFirestoreDesign(refDesign, {visible});
            this.resetSearch();
        },
    },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
    display: none;
}
</style>
