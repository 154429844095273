import { firestoreAction } from 'vuexfire';
import { refProjects } from '@/firebase';
import { userInGroupSuper } from '@/utils/authorization';

const updateProjectsBinding = async ({state, rootGetters, bindFirestoreRef, unbindFirestoreRef}) => {
    const user = rootGetters['authentication/getUser'];
    const roles = rootGetters['authentication/getRoles'];

    if (state.projectsViewers === 0) {
        return unbindFirestoreRef('boundQueryResults');
    }

    if (!user?.uid) {
        return unbindFirestoreRef('boundQueryResults');
    }

    if (userInGroupSuper(roles)) {
        return bindFirestoreRef('boundQueryResults', refProjects);
    }

    return bindFirestoreRef('boundQueryResults', refProjects.where('users', 'array-contains', user.uid));
};

export default {
    namespaced: true,

    state: {
        boundQueryResults: [],
        projectsViewers: 0,
    },

    getters: {},

    mutations: {
        ADD_PROJECTS_VIEWER: function (state) {
            state.projectsViewers = state.projectsViewers + 1;
        },
        REMOVE_PROJECTS_VIEWER: function (state) {
            state.projectsViewers = Math.max(0, state.projectsViewers - 1);
        },
    },

    actions: {
        /**
         * Voeg een viewer toe aan de teller van actieve kijkers
         */
        addProjectsViewer: firestoreAction(async (context) => {
            context.commit('ADD_PROJECTS_VIEWER');
            return await updateProjectsBinding(context);
        }),

        /**
         * Verwijder een viewer van de teller van actieve kijkers
         */
        removeProjectsViewer: firestoreAction(async (context) => {
            context.commit('REMOVE_PROJECTS_VIEWER');
            return await updateProjectsBinding(context);
        }),

        /**
         * Koppel de lijst met projecten zichtbaar voor de gebruiker in Firebase aan state.projects
         */
        updateProjectsBindings: firestoreAction(async context => await updateProjectsBinding(context)),
    }
};
