import { ROLE_ADMIN, ROLE_ARCHITECT, ROLE_DESIGNER, ROLE_SALES } from '@/utils';

export const userInGroupAdmin = (userRoles) => userRoles.indexOf(ROLE_ADMIN) > -1;
export const userInGroupArchitect = (userRoles) => userRoles.indexOf(ROLE_ARCHITECT) > -1;
export const userInGroupDesigners = (userRoles) => userRoles.indexOf(ROLE_DESIGNER) > -1;
export const userInGroupSales = (userRoles) => userRoles.indexOf(ROLE_SALES) > -1;

// Samengestelde groepen
export const userInGroupRodruza = (userRoles) => userInGroupSales(userRoles) || userInGroupDesigners(userRoles);
export const userInGroupSuper = (userRoles) => userInGroupAdmin(userRoles) || userInGroupRodruza(userRoles);
