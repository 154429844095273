import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/es5/locale/nl';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1904,
        },
    },
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            light: {},
            dark: {
                primary: '#f2530a',
            },
        },
    },
    lang: {
        locales: {nl},
        current: 'nl',
    },
});
