import Vue from 'vue';
import { firestorePlugin } from 'vuefire';
import { firestoreOptions } from 'vuexfire';

// Wacht altijd op de bindings alvorens de store te updaten
firestoreOptions.wait = true;

// Haal nooit automatisch alle referenties op (default is 2 niveau's automatisch ophalen)
firestoreOptions.maxRefDepth = 0;

Vue.use(firestorePlugin);
