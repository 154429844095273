import { firestore } from 'firebase';

export const refInvites = firestore().collection('invites');

/**
 * Creeer template voor nieuw invite
 * @param refProject
 * @returns {{project: *}}
 */
const createInvite = ({refProject}) => ({
    project: refProject,
    timestamp: firestore.FieldValue.serverTimestamp(),
});

/**
 * Maak een invite aan in Firestore en geef een referentie terug
 * @param projectName
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const createFirestoreInvite = async ({refProject}) => {
    const invite = createInvite({refProject});
    const refInvite = refInvites.doc();
    await refInvite.set(invite);

    return refInvite;
};

