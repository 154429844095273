<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                {{ $t('router.page_not_found') }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ThePageNotFound'
};
</script>

<style scoped>

</style>
