import { firestore } from 'firebase';
import { uploadFiles } from '@/firebase/storage';
import { newId } from '@/firebase';
import { userInGroupDesigners } from '@/utils/authorization';

/**
 * Creeer template voor een nieuw moodboard
 *
 * @return {{entries: [], users: []}}
 */
export const createMoodboard = () => ({
    entries: [],
    users: [],
});

export const refMoodboards = firestore().collection('moodboards');

/**
 * Maak een moodboard aan in Firestore en geef een referentie terug
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const createFirestoreMoodboard = (refProject, moodboardType) => {
    const moodboard = createMoodboard();
    const collectionMoodboard = refProject.collection('moodboards');
    const refMoodboard = collectionMoodboard.doc(moodboardType);

    return refMoodboard.set(moodboard).then(() => refMoodboard);
};

/**
 * Maak een entry-object aan om te plaatsen in een moodboard
 * @param {String} id - Uniek ID
 * @param heading
 * @param comment
 * @param files
 * @param links
 * @param {Object} user - Firebase user-object met de uid
 * @param design
 * @return {{created: firebase.firestore.FieldValue, files: *, comment: *, links: *}}
 */
const createMoodBoardEntry = ({id = null, heading = '', comment = '', files = [], links = [], user, design = false}) => ({
    id: id || newId(),
    heading,
    comment: comment || '',
    files: files || [],
    links: links || [],
    design,
    uid: user.uid,
    name: user.displayName,
    byDesigner: !!userInGroupDesigners(user.roles),
    created: firestore.Timestamp.now(),
});

/**
 * Maakt een object aan dat gebruikt kan worden als item in Firestore met verwijzing naar objecten als URL
 * Let op: dit doet momenteel niets, maar deze functie bestaat ook voor de andere collecties
 * @param {String} url
 * @param {String} title
 * @return {{title: String, url: String}}
 */
const createLinkEntry = ({url, title}) => ({
    url,
    title,
});

/**
 *
 * @param links
 * @return {*|*[]}
 */
const createMoodboardLinks = links => links?.map(link => createLinkEntry(link)) || [];

/**
 * Haal bestandsgegevens op, maak thumbnail en initieer uploads
 * @param {String} moodboardPath - Firestore pad dat gebruikt wordt als pad in Cloud Storage, ongeveer gelijk aan: projects/${projectId}/moodboards/${moodboardId}
 * @param files
 * @param moodboardEntryId
 * @return {Promise<[]>}
 */
const uploadMoodboardFiles = async (moodboardPath, files, moodboardEntryId) => uploadFiles(`${moodboardPath}/${moodboardEntryId}`, files);

/**
 * Voeg een entry toe aan de moodboard.
 * Een entry bestaat uit een comment, bestanden, links en een timestamp
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refMoodboard - Firebase documentreferentie naar het betreffende moodboard
 * @param {firebase.firestore.DocumentData} snapMoodboard - Firebase documentreferentie naar het betreffende moodboard
 * @param id
 * @param heading
 * @param comment
 * @param files
 * @param links
 * @param user
 * @param design
 * @return {boolean|Promise<DocumentReference>}
 */
export const addFirestoreMoodboardEntry = async ({refMoodboard, snapMoodboard}, {id, heading, comment, files, links, user, design}) => {
    if (typeof refMoodboard !== 'object' && typeof snapMoodboard !== 'object') {
        throw 'At least one of the parameters refMoodboard and snapMoodboard is required to add to the Moodboard';
    }

    const _snapMoodboard = snapMoodboard || await refMoodboard.get();
    const _refMoodboard = refMoodboard || _snapMoodboard.ref;

    if (!_snapMoodboard.exists) {
        return false;
    }

    const moodboard = _snapMoodboard.data();
    moodboard.entries.push(createMoodBoardEntry({id, heading, comment, files, links, user, design}));
    await _refMoodboard.update({entries: moodboard.entries});

    return _refMoodboard;
};

/**
 * Voeg een entry toe aan de moodboard.
 * Een entry bestaat uit een comment, bestanden, links en een timestamp
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refMoodboard - Firebase documentreferentie naar het betreffende moodboard
 * @param entryData
 * @return {boolean|Promise<DocumentReference>}
 */
export const uploadFilesAndAddFirestoreMoodboardEntry = async (refMoodboard, entryData) => {
    const snapMoodboard = await refMoodboard.get();

    if (!snapMoodboard.exists) {
        return false;
    }

    const moodboardEntryId = newId();
    const files = await uploadMoodboardFiles(refMoodboard.path, entryData.files, moodboardEntryId);
    const links = createMoodboardLinks(entryData.links);

    return addFirestoreMoodboardEntry({refMoodboard, snapMoodboard}, {
        id: moodboardEntryId,
        ...entryData,
        files,
        links,
    });
};
