import axios from 'axios';

export default axios.create({
    // Om de variabelen correct te versturen als x-www-form-urlencoded moet de data omgezet worden
    transformRequest: [
        (data, headers) => {
            // https://stackoverflow.com/questions/41457181/axios-posting-params-not-read-by-post
            const params = new URLSearchParams();
            Object.keys(data).forEach(key => params.append(key, data[key]));
            return params;
        }
    ],
});
