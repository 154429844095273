export default {
    namespaced: true,

    state: {
        src: '',
        lazySrc: '',
        aspectRatio: '1.2',
        isVisible: false,
    },

    getters: {
        src: state => state.src,
        lazySrc: state => state.lazySrc,
        aspectRatio: state => state.aspectRatio,
        isVisible: state => state.isVisible,
    },

    mutations: {
        SET_SRC (state, src) {
            state.src = src;
        },
        SET_LAZYSRC (state, lazySrc) {
            state.lazySrc = lazySrc;
        },
        SET_ASPECTRATIO (state, aspectRatio) {
            state.aspectRatio = aspectRatio;
        },
        SET_IS_VISIBLE (state, isVisible) {
            state.isVisible = isVisible;
        },
    },

    actions: {
        show ({commit}, {src, lazySrc = '', aspectRatio = '1.2'}) {
            commit('SET_SRC', src);
            commit('SET_LAZYSRC', lazySrc);
            commit('SET_ASPECTRATIO', aspectRatio);
            commit('SET_IS_VISIBLE', true);
        },
        hide ({commit}) {
            commit('SET_SRC', '');
            commit('SET_LAZYSRC', '');
            commit('SET_ASPECTRATIO', '1.2');
            commit('SET_IS_VISIBLE', false);
        },
    }
};
