import i18n from '@/plugins/i18n';

const TEST_NAME = /^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$/;
const TEST_EMAIL = /.+@[^.]+\..+/;
const TEST_PASSWORD = /.{8}/;
const TEST_URL = /^(https?:\/\/)?[^.]+\.[^.]+/;
const TEST_ALPHNUM_EXTENDED = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9- ]+$/;

export const RULES_FIELD_REQUIRED = [v => !!v || i18n.t('global.field_required')];
export const RULES_NAME_REQUIRED = [v => !!v || i18n.t('registration.name_required')];
export const RULES_NAME_VALIDATE = [v => TEST_NAME.test(v) || i18n.t('registration.incorrect_name')];
export const RULES_EMAIL_REQUIRED = [v => !!v || i18n.t('global.email_required')];
export const RULES_EMAIL_VALIDATE = [v => TEST_EMAIL.test(v) || i18n.t('global.incorrect_email')];
export const RULES_PASSWORD_REQUIRED = [v => !!v || i18n.t('registration.password_required')];
export const RULES_PASSWORD_VALIDATE = [v => TEST_PASSWORD.test(v) || i18n.t('registration.password_too_short')];
export const RULES_URL_VALIDATE = [v => TEST_URL.test(v) || i18n.t('global.incorrect_url')];

export const restrictUploadSizeTo = MiB => [file => !file || file.size <= MiB * 1024 * 1024 || i18n.t('upload.file_should_be_less_then', [MiB])];
export const RULES_UPLOAD_SIZE_10MB = restrictUploadSizeTo(10);
export const RULES_UPLOAD_SIZE_250MB = restrictUploadSizeTo(250);

export const IMPORT_IMAGE_MAX_DIMENSIONS = 3000;
export const IMPORT_ACCEPTED_IMAGETYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
export const IMPORT_ACCEPTED_FILETYPES = [...IMPORT_ACCEPTED_IMAGETYPES];
export const isImageType = type => IMPORT_ACCEPTED_IMAGETYPES.indexOf(type) > -1;

export const ROLE_ADMIN = 'admin'; // 1
export const ROLE_ARCHITECT = 'architect'; // 0
export const ROLE_DESIGNER = 'designer'; // 4
export const ROLE_SALES = 'sales'; // 2

export const PROJECT_UPLOAD_AGREEMENT = 'agreement';
export const PROJECT_UPLOAD_NDA = 'nda';
export const PROJECT_UPLOAD_QUOTATION = 'quotation';
export const PROJECT_UPLOAD_QUOTATION_SIGNED = 'signed';

export const MOODBOARD_WORKPLACE = 'workplace';
export const MOODBOARD_PROTOTYPE = 'proto';

export const FORM_AGREEMENT = 'agreement';
export const FORM_START = 'start';

const collection = (name, subcollections) => ({name, subcollections});
export const BRICK_COLLECTIONS = [
    collection('nature', ['tundra', 'forest', 'desert', 'mediterranean', 'mountains']),
    collection('urban', ['cities', 'landscapes', 'architectural elements', 'roads', 'interior']),
    collection('imaginative', ['dreams', 'shades', 'shadows', 'experiments', 'inspired']),
    collection('ornament', ['patterns', 'drawings', 'geometric', 'lines', 'structural']),
    collection('traditional', []),
];
export const BRICK_COLORS = ['white', 'pistache', 'grey', 'black', 'red', 'natural', 'brown', 'vanilla', 'orange'];
export const BRICK_SURFACES = ['wasserstrich', 'handvorm'];

export const ucFirst = val => val.substring(0, 1).toUpperCase() + val.substring(1);
