<template>
    <v-simple-table>
        <template #default>
            <thead>
            <tr>
                <th v-if="prependColumn !== false">{{ prependColumn === false ? '' : prependColumn }}</th>
                <th class="text-left">{{ $t('workflow_design.design_name') }}</th>
                <th class="text-left">{{ $t('workflow_design.design_created') }}</th>
                <th v-if="appendColumn !== false">{{ appendColumn === false ? '' : appendColumn }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="design in designs" :key="design.id">
                <td v-if="prependColumn !== false">
                    <slot name="prepend" :design="design"></slot>
                </td>
                <td class="tiemposText text-left">{{ design.name }}</td>
                <td class="tiemposText text-left">{{ design.timestamp | formatDateTime }}</td>
                <td v-if="appendColumn !== false">
                    <slot name="append" :design="design"></slot>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { formatDateTime } from '@/utils/vue-filters';

export default {
    name: 'DesignListTabular',

    props: {
        prependColumn: {
            type: Boolean | String,
            default: false,
        },
        appendColumn: {
            type: Boolean | String,
            default: false,
        },
        designs: {
            type: Array,
            required: true,
        },
    },

    filters: {
        formatDateTime: formatDateTime,
    },
};
</script>

<style scoped>

</style>
