<template>
    <v-dialog v-model="showDialog" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" class="float-right mr-4 boxed-icon" tile small elevation="0" :disabled="disabled">
                {{ $t('request_project_dialog.request_project') }}
                <v-icon right>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('request_project_dialog.request_project') }}</v-card-title>
            <v-card-text>
                <v-form v-model="formValid" class="mt-4">
                    <v-text-field v-model="projectName" :label="$t('project.name') + ' *'" :rules="ruleNotEmpty" required></v-text-field>
                    <v-textarea v-model="projectDescription" :label="$t('project.description')" required/>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showDialog = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn @click="requestProject" color="primary" type="submit" :disabled="!formValid" class="boxed-icon pr-0 mr-4" tiles elevation="0" :loading="loading">
                    {{ $t('request_project_dialog.request') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { RULES_FIELD_REQUIRED } from '@/utils';

export default {
    name: 'ProjectRequestDialog',

    props: {
        disabled: Boolean,
    },

    data: () => ({
        ruleNotEmpty: [...RULES_FIELD_REQUIRED],
        projectName: '',
        formValid: null,
        projectDescription: '',
        showDialog: false,
        loading: false,
    }),

    methods: {
        resetForm () {
            this.projectName = '';
            this.projectDescription = '';
        },

        requestProject () {
            this.loading = true;
            this.$store.dispatch('project/requestProject', {
                projectName: this.projectName,
                projectDescription: this.projectDescription,
            }).then(() => {
                this.$store.dispatch('statusMessage/success', 'The project request has been sent');
            }).catch(() => {
                this.$store.dispatch('statusMessage/error', 'There was an error while sending the project request, please try again');
            }).finally(() => {
                this.loading = false;
                this.resetForm();
                this.showDialog = false;
            });
        },
    },
};
</script>

<style scoped>

</style>
