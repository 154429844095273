/*
 * TODO
 *   - Moodboard naar subcollecties vanwege gewijzigde structuur
 *   - maxRefDepth terugbrengen naar 0
 */
import { firestoreAction } from 'vuexfire';
import { refProjects } from '@/firebase';
import { createFirestoreProject } from '@/firebase/firestore/projects';
import { userInGroupSuper } from '@/utils/authorization';
import { createMoodboard } from '@/firebase/firestore/projects/moodboards';
import axios from 'axios';
import { auth } from 'firebase';

/**
 * Custom serializer zodat de Firestore-referentie behouden blijft. Dit maakt
 * het mogelijk de referentie te gebruiken in andere delen van de code.
 * @param snapshot
 * @return {Object}
 */
export function createSnapshot (snapshot) {
    const data = snapshot.data() || {};
    Object.defineProperty(data, 'id', {value: snapshot.id});
    Object.defineProperty(data, '$ref', {value: snapshot.ref});
    return data;
}

const updateProjectBinding = async ({state, commit, rootGetters, bindFirestoreRef, unbindFirestoreRef}) => {
    const user = rootGetters['authentication/getUser'];

    commit('SET_DESIGNS', []);

    if (user?.uid && state.boundInstanceId) {
        const refProject = refProjects.doc(state.boundInstanceId);
        const roles = rootGetters['authentication/getRoles'];

        if (!userInGroupSuper(roles)) {
            const project = await refProject.get();
            if (project.data().users.indexOf(user.uid) === -1) {
                return unbindFirestoreRef('boundInstance');
            }
        }

        return bindFirestoreRef('boundInstance', refProject, {maxRefDepth: 1, serialize: createSnapshot});
    }

    return unbindFirestoreRef('boundInstance');
};

export default {
    namespaced: true,

    state: {
        boundInstanceId: null,
        boundInstance: null,
        designs: [],
    },

    getters: {
        get: state => state.boundInstance,

        workplace: state => {
            // Noodzakelijk vanwege async koppelen van referenties (Vuexfire)
            if (typeof state.boundInstance?.workplace !== 'object') {
                return createMoodboard();
            }

            return state.boundInstance.workplace;
        },

        proto: state => {
            // Noodzakelijk vanwege async koppelen van referenties (Vuexfire)
            if (typeof state.boundInstance?.proto !== 'object') {
                return createMoodboard();
            }

            return state.boundInstance.proto;
        },
    },

    mutations: {
        SET_DESIGNS: function (state, value) {
            state.designs = value;
        },

        SET_INSTANCE_ID: function (state, value) {
            state.boundInstanceId = value;
        },
    },

    actions: {
        /**
         * Creeeren van een nieuw project maakt het project
         * @param commit
         * @param projectName
         */
        async createProject ({commit}, {projectName}) {
            return await createFirestoreProject({projectName});
        },

        /**
         * Nieuwe project aanvraag indienen
         * @param commit
         * @param projectName {string}
         * @param projectDescription {string}
         * @returns {Promise<void>}
         */
        async requestProject ({commit, rootState}, {projectName, projectDescription}) {
            const idToken = await auth().currentUser?.getIdTokenResult();
            // process.env.VUE_APP_FIREBASE_FUNCTIONS_RESOURCE_REQUEST_PROJECT
            return await axios.post('http://localhost:5001/rodruza-brickdesigner/europe-west1/v1/project/request', {
                projectName,
                projectDescription,
                idToken
            });
        },

        /**
         * Verwijder het project met ID
         * Let op: nog niet ingebouwd. Gooit het project niet weg in Firebase
         * @param commit
         * @param state
         * @param projectId
         */
        deleteProject ({commit, state}, projectId) {
            if (state.boundInstanceId === projectId) {
                commit('SET_INSTANCE_ID', null);
            }

            console.error('deleting projects is not implemented');
        },

        setProject: firestoreAction(async (context, boundInstanceId) => {
            context.commit('SET_INSTANCE_ID', boundInstanceId);
            return await updateProjectBinding(context);
        }),

        /**
         * Koppel het actieve project uit Firebase aan state.boundInstance
         */
        updateProjectBindings: firestoreAction(async context => await updateProjectBinding(context)),

        /**
         * Haal designs van het project op bij Firebase en update de lijst
         */
        fetchDesigns ({commit, state}, refresh = false) {
            if (!refresh && state.designs.length) {
                // Er zijn al ontwerpen in de lijst
                return;
            }

            commit('SET_DESIGNS', []);
        },
    }
};
