import Vue from 'vue';
import VueRouter from 'vue-router';
import TheHome from '../views/TheHome.vue';
import TheProject from '../views/TheProject';
import TheProjects from '../views/TheProjects.vue';
import ThePageNotFound from '@/views/ThePageNotFound';
import { ROUTER_PROJECTS } from '@/router/projects';
import { userInGroupAdmin, userInGroupDesigners, userInGroupSuper } from '@/utils/authorization';
import { auth } from 'firebase';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'TheHome',
        component: TheHome,
    },
    {
        path: '/login',
        name: 'TheLogin',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/TheLogin.vue'),
    },
    {
        path: '/projects',
        name: 'TheProjects',
        component: TheProjects,
        beforeEnter: async (to, from, next) => {
            // idToken.claims.roles is gelijk aan this.$store.getters['authentication/getRoles']
            // We kunnen de store beter niet importeren in de router, omdat de store al de router importeert
            const idToken = await auth().currentUser?.getIdTokenResult();
            if (idToken) {
                next();
            } else {
                // Terug naar een veilige route
                next({name: 'TheLogin'});
            }
        },
    },
    {
        path: '/projects/:projectId',
        name: 'TheProject',
        component: TheProject,
        children: ROUTER_PROJECTS,
    },
    {
        path: '/dashboard',
        name: 'TheDashboard',
        // Chunk omdat dit enkel voor admin toegankelijk is
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/TheDashboard.vue'),
        beforeEnter: async (to, from, next) => {
            // idToken.claims.roles is gelijk aan this.$store.getters['authentication/getRoles']
            // We kunnen de store beter niet importeren in de router, omdat de store al de router importeert
            const idToken = await auth().currentUser?.getIdTokenResult();
            if (userInGroupAdmin(idToken?.claims?.roles || []) || userInGroupDesigners(idToken?.claims?.roles || [])) {
                next();
            } else {
                // Terug naar een veilige route
                next({name: 'TheLogin'});
            }
        },
    },
    {
        path: '/invite/:inviteId',
        name: 'TheProjectInvite',
        component: () => import(/* webpackChunkName: "invite" */ '@/views/TheProjectInvite.vue'),
    },
    {
        path: '/page-not-found',
        component: ThePageNotFound,
    },
    {
        path: '*',
        name: 'PageNotFound',
        redirect: '/page-not-found',
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

/**
 * Vang de melding NavigationDuplicated af.
 * Deze komt voor bij navigatie naar de huidige pagina.
 */
['push', 'replace'].forEach((methodName) => {
    router['original' + methodName] = router[methodName];
    router[methodName] = async function (location) {
        return router['original' + methodName](location).catch((error) => {
            if (error.name === 'NavigationDuplicated') {
                // Geef locatie terug zodat de verdere afhandeling weer then-able is.
                return this.currentRoute;
            }

            throw error;
        });
    };
});

// Scoll naar bovenkant pagina
router.beforeEach(async (to, from, next) => {
    window.scroll({top: 0, behavior: 'smooth'});
    next();
});

export default router;
