import Vue from 'vue';
import { auth } from 'firebase';
import router from '@/router';
import { ROLE_ARCHITECT } from '@/utils';
import { updateUserDisplayName } from '@/firebase';

/**
 * Log in met de e-mail/wachtwoord optie uit Firebase Authentication
 * @param email
 * @param password
 * @returns {Promise<firebase.auth.UserCredential>}
 */
const actionUserSignInWithEmailAndPassword = ({email, password}) => auth().signInWithEmailAndPassword(email, password);

/**
 * Log in via Google accounts
 * @returns {Promise<firebase.auth.UserCredential>}
 */
const actionUserSignInWithGooglePopup = () => {
    const provider = new auth.GoogleAuthProvider();
    return auth().signInWithPopup(provider);
};

/**
 * Verwerk inloggen met gekozen inlogmethode
 * @param {String} method - Inlogmethode, bijv. "google-popup" of "email"
 * @param {String} email
 * @param {String} password
 * @returns {Promise<firebase.auth.UserCredential>|Promise<never>}
 */
const userSignIn = ({method = 'email', email, password}) => {
    switch (method) {
        case 'google-popup':
            return actionUserSignInWithGooglePopup();

        case 'email':
            return actionUserSignInWithEmailAndPassword({email, password});

        default:
            return Promise.reject('Unknown signin method provided: "' + method + '"');
    }
};

/**
 * Zorg dat de rollen altijd een waarde teruggeven. De rol van architect is de fallback.
 * @param roles
 * @return {*|string[]}
 */
const normalizeRoles = roles => roles || [ROLE_ARCHITECT];

/**
 * Alle aanroepen naar deze module verlopen via een namespace
 *
 * Voorbeeld:
 *  this.$store.getters['authentication/isAuthenticated']
 *
 * @type {boolean}
 */
const namespaced = true;

const state = {
    user: null,
    claims: null,
};

const mutations = {
    SET_CLAIMS (state, payload) {
        if (!payload) {
            return state.claims = null;
        }

        Vue.set(state, 'claims', {
            roles: normalizeRoles(payload.roles),
        });

        // TODO: 1 plek voor rollen
        Vue.set(state.user, 'roles', normalizeRoles(payload.roles));
    },
    SET_USER (state, payload) {
        if (!payload) {
            return state.user = null;
        }

        Vue.set(state, 'user', {
            uid: payload.uid,
            displayName: payload.displayName,
            email: payload.email,
            emailVerified: payload.emailVerified,
            roles: normalizeRoles(payload.roles),
        });
    },
};

const actions = {
    /**
     * @see userSignIn
     * @param commit
     * @param signinOptions
     * @returns {Promise<* | boolean>}
     */
    userSignIn ({commit, dispatch}, signinOptions) {
        return userSignIn(signinOptions)
            .catch(error => {
                commit('SET_USER', null);

                // Toon melding van firebase aan gebruiker
                dispatch('statusMessage/error', error, {root: true});

                throw error;
            });
    },

    /**
     * Maak e-mail/wachtwoord account aan bij Firebase Authentication.
     * @param commit
     * @param {String} displayName
     * @param {String} email
     * @param {String} password
     * @returns {Promise<* | boolean>}
     */
    userJoin ({commit, dispatch}, {displayName, email, password}) {
        return auth()
            .createUserWithEmailAndPassword(email, password)
            // Stel extra gebruikersdata in na registratie
            .then(userData => updateUserDisplayName(userData.user, displayName)
                .then(() => userData)
                .catch(() => userData)
            )
            // .then(userData => userData.user.sendEmailVerification()
            //     .then(() => userData)
            //     .catch(error => {
            //         // Toon melding van firebase aan gebruiker
            //         dispatch('statusMessage/error', error, {root: true});
            //     }))
            .catch(error => {
                commit('SET_USER', null);

                // Toon melding van firebase aan gebruiker
                dispatch('statusMessage/error', error, {root: true});

                // Foutmelding afvangen
                return false;
            });
    },

    /**
     * Log de gebruiker uit bij Firebase Authentication.
     * Let op: dit veroorzaakt geen uitlog in de applicatie/alle tabbladen
     * @param commit
     */
    userSignOut ({commit}) {
        auth()
            .signOut()
            .finally(() => {
                commit('SET_USER', null);
                router.push('/');
            });
    },

    /**
     * Start een observer die de context van de store heeft zodat de app automatisch in kan loggen
     * Let op: deze functie kan maar 1 keer aangeroepen worden, anders ontstaan er twee observers
     * @param commit
     * @param rootState
     */
    monitorUserStatus ({commit, rootState}) {
        auth().onAuthStateChanged((user) => {
            if (user) {
                // Stelt ook de user claims in (en filtert ongewenste claims uit te lijst)
                user.getIdTokenResult().then(idTokenResult => {
                    let claims = {...idTokenResult.claims};
                    ['iss', 'firebase'].forEach(strKey => delete claims[strKey]);
                    commit('SET_USER', Object.assign({}, user));
                    commit('SET_CLAIMS', claims);
                });
            } else {
                commit('SET_USER', null);
                commit('SET_CLAIMS', null);
            }
        });
    },
};

const getters = {
    getUser: state => state.user || {displayName: '', roles: normalizeRoles(null), uid: null},
    getRoles: state => normalizeRoles(state.claims ? state.claims.roles : null),

    /**
     * Geeft 2 hoofdletters terug van de naam van de gebruiker
     * @param state
     * @return {string}
     */
    getAvatarInitials: state => {
        if (!state.user || !state.user.displayName) {
            return '..';
        }

        // Haal de naam op en verwijder alle niet-geldige karakters
        const displayName = state.user.displayName.replace('[^0-9A-Za-zÀ-ÖØ-öø-ÿ ]', '').trim();

        // Als er saties in de naam zijn nemen we het eerste deel en het laatse deel en nemen van beide het eerste karakter
        if (displayName.indexOf(' ') > 0) {
            const chunks = displayName.split(/ +/);
            return chunks[0].substring(0, 1).toUpperCase() + chunks[chunks.length - 1].substring(0, 1).toUpperCase();
        }

        // Als fallback nemen we de eerste twee karakters uit de naam
        return displayName.substring(0, 2).toUpperCase();
    },

    isAuthenticated: state => state.user !== null && typeof state.user === 'object',
};

export default {namespaced, state, mutations, actions, getters};
