import loadImage from 'blueimp-load-image';
import { IMPORT_IMAGE_MAX_DIMENSIONS, isImageType } from '@/utils/index';
import { canvasRGB } from 'stackblur-canvas';
import { changeFilePathExtension } from '@/firebase/storage';

/**
 * Kopieer het canvas naar een nieuwe, inclusief getekende inhoud
 * @param {HTMLCanvasElement|Image} oldCanvas
 * @return {HTMLCanvasElement}
 */
export const canvasClone = oldCanvas => {
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');

    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;

    context.drawImage(oldCanvas, 0, 0);

    return newCanvas;
};

/**
 * Gaussian blur van het canvas
 * @param {HTMLCanvasElement} targetCanvas
 * @param {Number} radius
 */
export const canvasBlur = (targetCanvas, radius) => canvasRGB(targetCanvas, 0, 0, targetCanvas.width, targetCanvas.height, radius);

/**
 * Haal de blob uit uit een canvasobject ingepakt in een Promise
 * @param canvas
 * @param {String} mimeType - Gewenste output
 * @param {Number|null} qualityArgument - Getal tussen 0 en 1, als het iets anders is wordt de default gebruikt (0.92)
 * @return {Promise<Blob>}
 */
export const canvasBlob = (canvas, mimeType = 'image/png', qualityArgument = null) => new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
        resolve(blob);
    }, mimeType, qualityArgument);
});

/**
 * Herschaal een afbeelding naar maxWidth
 * @param {Blob|String} file - Blob object or image URL
 * @param options
 * @return {Promise<Blob>}
 */
export const imageScale = async (file, options = {maxWidth: 200}) => {
    try {
        if (isImageType(file.type)) {
            const data = await loadImage(file);
            return loadImage.scale(data.image, {downsamplingRatio: .5, canvas: true, ...options});
        }
    } catch (e) {
        console.error('Error creating thumbnail. ' + e.name + ': ' + e.message);
    }

    return void (0);
};

/**
 * Herschaal een afbeelding met mogelijkheid om herschaling te verplichten als de afbeelding in bestandsgrootte te groot is.
 * Let op: breedte en hoogte kan niet bepaald worden voor File-uploads, er wordt dan altijd een poging tot herschalen gedaan
 * @param {File|Blob|String} file - Blob object or image URL
 * @param {Number} thresholdFileSize - Maximale bestandsgrootte in bytes. Bestanden groter worden aangepast maar kleiner wordt niet aangepast
 * @param {Number} thresholdDimensions - Maximale afmeting voor de afbeelding in pixels.
 * @param {String} targetMimeType - Outputsoort (image/jpeg is standaard)
 * @returns {Promise<Blob|File>}
 */
export const imageRestrict = async (file, {thresholdFileSize = null, thresholdDimensions = IMPORT_IMAGE_MAX_DIMENSIONS, targetMimeType = 'image/jpeg'}) => {
    if (!isImageType(file.type)) {
        return file;
    }

    // Bepaal of het bestand verkleind moet worden
    const exceedsFileSize = typeof thresholdFileSize === 'number' ? (file.size > thresholdFileSize) : false;
    const exceedsDimensions = typeof thresholdDimensions === 'number' && file.width ? (file.width > thresholdDimensions || file.height > thresholdDimensions) : false;
    if (!exceedsFileSize && !exceedsDimensions) {
        return file;
    }
    if (!exceedsFileSize) {
        return file;
    }

    // Doel voor herschalen
    const maxDimensions = Math.min(thresholdDimensions, IMPORT_IMAGE_MAX_DIMENSIONS, !file.width ? IMPORT_IMAGE_MAX_DIMENSIONS : Math.max(file.width, file.height));

    // Bepaal extensie (gaat goed zolang IMPORT_ACCEPTED_IMAGETYPES niet wordt aangepast)
    const targetExtension = targetMimeType === 'image/jpeg' ? 'jpg' : targetMimeType.substr(6);

    const fileName = file.name;
    const resized = await imageScale(file, {maxWidth: maxDimensions, maxHeight: maxDimensions});
    const blobOutput = await canvasBlob(resized, targetMimeType);
    blobOutput.name = changeFilePathExtension(fileName, targetExtension);

    return blobOutput;
};
