<template>
    <v-row no-gutters dense>
        <v-col cols="4" class="left-sidebar px-10 py-8">
            <svg class="logo float-right" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30.2 10.3" style="enable-background:new 0 0 30.2 10.3; width: 90px;" xml:space="preserve">
                        <g>
                            <path class="fill-logo" d="M4,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6H4L4,4.2z M5.4,4.8c0-0.7-0.5-1.1-1.2-1.1H3.4v3.2H4 V5.8l0.8,1.1h0.8L4.7,5.8C5.1,5.6,5.4,5.3,5.4,4.8"/>
                            <path class="fill-logo" d="M8,6.3c-0.6,0-1-0.4-1-1.1c0-0.6,0.4-1,1-1s1,0.4,1,1C9,5.9,8.6,6.3,8,6.3 M8,3.6c-1,0-1.7,0.7-1.7,1.7 c0,1,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7C9.7,4.3,9,3.6,8,3.6"/>
                            <path class="fill-logo" d="M11.5,4.2h0.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-0.2V4.2z M11.7,3.6h-0.9v3.2h0.9c1,0,1.6-0.6,1.6-1.6 C13.4,4.3,12.7,3.6,11.7,3.6"/>
                            <path class="fill-logo" d="M15.2,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6h-0.1L15.2,4.2z M16.6,4.8c0-0.7-0.5-1.1-1.2-1.1 h-0.8v3.2h0.6V5.8L16,6.9h0.8l-0.8-1.1C16.3,5.6,16.6,5.3,16.6,4.8"/>
                            <path class="fill-logo" d="M19.6,5.6c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.6-0.3-0.6-0.7v-2h-0.6v2c0,0.8,0.5,1.3,1.2,1.3 c0.7,0,1.2-0.5,1.2-1.3v-2h-0.6L19.6,5.6L19.6,5.6z"/>
                            <polygon class="fill-logo" points="21.4,3.6 21.4,4.2 22.7,4.2 21.3,6.9 23.6,6.9 23.6,6.3 22.3,6.3 23.7,3.6 		"/>
                            <path class="fill-logo" d="M26.3,5.8h-0.8l0.4-0.9L26.3,5.8z M25.9,3.4l-1.5,3.5h0.6l0.2-0.5h1.3l0.2,0.5h0.7L25.9,3.4z"/>
                        </g>
                <path class="fill-logo" d="M0.6,0.6h29v9.1h-29V0.6z M0,10.3h30.2V0H0V10.3z"/>
                </svg>
            <v-tabs light v-model="tab">
                <v-tab>{{ $t('project.ui.tab_status') }}</v-tab>
                <v-tab>{{ $t('project.ui.tab_comments') }}</v-tab>
            </v-tabs>
            <v-tabs-items light v-model="tab">
                <v-tab-item>
                    <v-card flat v-if="project"><h1 class="text-h1 pt-4">{{ $t('project.project_heading') }}</h1>
                        <h2 class="text-h2 pb-2">&ldquo;{{ project.name }}&rdquo;</h2>
                        <v-stepper v-model="step" vertical class="mt-4">
                            <v-stepper-step step="1" :editable="$isStepEditable(PROJECT_START)" :complete="$isStepCompleted(PROJECT_START)">
                                <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_START)" @click="$gotoStep(PROJECT_START)">
                                    <v-icon small color="gray">mdi-eye-outline</v-icon>
                                </v-btn>
                                <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_start') }}</h6>
                                <small>{{ $t('workflow.step_subtitle_start') }}</small>
                            </v-stepper-step>
                            <v-stepper-content step="1" v-if="!$isStepCompleted(PROJECT_START)">
                                <v-card color="grey lighten-3" elevation="0" class="pa-3">
                                    <div class="tiemposText mb-2">{{ $t('workflow.step_content_start') }}</div>
                                    <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_START)" :disabled="!$isStepReleased(PROJECT_START)" class="mt-1 boxed-icon" color="primary" small tile @click="$approveStep(PROJECT_START)">
                                        {{ $t('workflow.save_continue') }}
                                        <v-icon right>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-step step="2" :editable="$isStepEditable(PROJECT_AGREEMENT)" :complete="$isStepCompleted(PROJECT_AGREEMENT)">
                                <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_AGREEMENT)" @click="$gotoStep(PROJECT_AGREEMENT)">
                                    <v-icon small color="gray">mdi-eye-outline</v-icon>
                                </v-btn>
                                <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_agreement') }}</h6>
                                <small>{{ $t('workflow.step_subtitle_agreement') }}</small>
                            </v-stepper-step>
                            <v-stepper-content step="2" v-if="!$isStepCompleted(PROJECT_AGREEMENT)">
                                <v-card color="grey lighten-3" elevation="0" light class="pa-3">
                                    <div class="tiemposText mb-2">{{ $t('workflow.step_content_agreement') }}</div>
                                    <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_AGREEMENT)" :disabled="!$isStepReleased(PROJECT_AGREEMENT)" class="mt-1 boxed-icon" color="primary" tile @click="$approveStep(PROJECT_AGREEMENT)">
                                        {{ $t('workflow.agree') }}
                                        <v-icon right>mdi-arrow-right</v-icon>
                                    </v-btn>
                                    <comment-add-dialog v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_AGREEMENT)" v-slot="{ attrs, on }"
                                                        :context="$t('agreement.context_on_agreement')" :project="project" :user="user" :roles="roles">
                                        <v-btn v-bind="attrs" v-on="on" class="mt-1 ml-5 boxed-icon" color="black" small outlined tile elevation="0">
                                            {{ $t('project.ui.add_comment') }}
                                            <v-icon right>mdi-comment-processing-outline</v-icon>
                                        </v-btn>
                                    </comment-add-dialog>
                                </v-card>
                            </v-stepper-content>

                            <div class="co-creation-steps">
                                <div style="transform: translateX(-8px); margin-top: -15px;" class="overline">{{ $t('workflow.group_heading_design_cocreate') }}</div>
                                <v-stepper-step step="3" :editable="$isStepEditable(PROJECT_WORKPLACE)" :complete="$isStepCompleted(PROJECT_WORKPLACE)">
                                    <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_WORKPLACE)" @click="$gotoStep(PROJECT_WORKPLACE)">
                                        <v-icon small color="gray">mdi-eye-outline</v-icon>
                                    </v-btn>
                                    <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_workplace') }}</h6>
                                    <small class="compact">{{ $t('workflow.step_subtitle_workplace') }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="3" v-if="!$isStepCompleted(PROJECT_WORKPLACE)">
                                    <v-card color="grey lighten-3" elevation="0" class="pa-3">
                                        <div class="tiemposText mb-2">{{ $t('workflow.step_content_workplace') }}</div>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-step step="4" :editable="$isStepEditable(PROJECT_DESIGN)" :complete="$isStepCompleted(PROJECT_DESIGN)">
                                    <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_DESIGN)" @click="$gotoStep(PROJECT_DESIGN)">
                                        <v-icon small color="gray">mdi-eye-outline</v-icon>
                                    </v-btn>
                                    <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_design') }}</h6>
                                    <small class="compact">{{ $t('workflow.step_subtitle_design') }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="4" v-if="!$isStepCompleted(PROJECT_DESIGN)">
                                    <v-card color="grey lighten-3" elevation="0" class="pa-3">
                                        <div class="tiemposText">{{ $t('workflow.step_content_design') }}</div>
                                        <comment-add-dialog v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_DESIGN)" v-slot="{ attrs, on }"
                                                            :context="$t('workflow_design.context_on_design' , [''])" :project="project" :user="user" :roles="roles">
                                            <v-btn v-bind="attrs" v-on="on" class="mt-1 boxed-icon" color="black" small outlined tile elevation="0">
                                                {{ $t('project.ui.add_comment') }}
                                                <v-icon right>mdi-comment-processing-outline</v-icon>
                                            </v-btn>
                                        </comment-add-dialog>
                                    </v-card>
                                </v-stepper-content>
                                <v-stepper-step step="5" :editable="$isStepEditable(PROJECT_PROTOTYPE)" :complete="$isStepCompleted(PROJECT_PROTOTYPE)">
                                    <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_PROTOTYPE)" @click="$gotoStep(PROJECT_PROTOTYPE)">
                                        <v-icon small color="gray">mdi-eye-outline</v-icon>
                                    </v-btn>
                                    <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_prototype') }}</h6>
                                    <small class="compact">{{ $t('workflow.step_subtitle_prototype') }}</small>
                                </v-stepper-step>
                                <v-stepper-content step="5" v-if="!$isStepCompleted(PROJECT_PROTOTYPE)">
                                    <v-card color="grey lighten-3" elevation="0" class="pa-2">
                                        <div class="tiemposText">{{ $t('workflow.step_content_prototype') }}</div>
                                        <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_PROTOTYPE)" :disabled="!$isStepReleased(PROJECT_PROTOTYPE)" color="primary" class="mr-2 mt-2 boxed-icon" small @click="$approveStep(PROJECT_PROTOTYPE)">
                                            {{ $t('global.approve') }}
                                            <v-icon right>mdi-check</v-icon>
                                        </v-btn>
                                        <comment-add-dialog v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_PROTOTYPE)" v-slot="{ attrs, on }"
                                                            :context="$t('workflow_prototype.context_on_prototype')" :project="project" :user="user" :roles="roles">
                                            <v-btn v-bind="attrs" v-on="on" class="mt-2 boxed-icon" color="black" small outlined tile elevation="0">
                                                {{ $t('project.ui.add_comment') }}
                                                <v-icon right>mdi-comment-processing-outline</v-icon>
                                            </v-btn>
                                        </comment-add-dialog>
                                    </v-card>
                                </v-stepper-content>
                            </div>

                            <v-stepper-step step="6" :editable="$isStepEditable(PROJECT_PRODUCTION)" :complete="$isStepCompleted(PROJECT_PRODUCTION)">
                                <v-btn small color="gray" class="stepper-view" outlined icon v-if="$isStepCompleted(PROJECT_PRODUCTION)" @click="$gotoStep(PROJECT_PRODUCTION)">
                                    <v-icon small color="gray">mdi-eye-outline</v-icon>
                                </v-btn>
                                <h6 class="tiemposText tiemposText--bold text-h6">{{ $t('workflow.step_heading_production') }}</h6>
                                <small>{{ $t('workflow.step_subtitle_production') }}</small>
                            </v-stepper-step>
                            <v-stepper-content step="6">
                                <v-card color="grey lighten-3" class="mb-8 pa-3" elevation="0">
                                    <div class="tiemposText">{{ $t('workflow.step_content_production') }}</div>
                                    <comment-add-dialog v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_PRODUCTION)" v-slot="{ attrs, on }"
                                                        :context="$t('workflow_production.context_on_quotation')" :project="project" :user="user" :roles="roles">
                                        <v-btn v-bind="attrs" v-on="on" class="mt-1 boxed-icon" color="black" small outlined tile elevation="0">
                                            {{ $t('project.ui.add_comment') }}
                                            <v-icon right>mdi-comment-processing-outline</v-icon>
                                        </v-btn>
                                    </comment-add-dialog>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="comment-timeline">
                        <the-comment-timeline></the-comment-timeline>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
        <v-col cols="8" class="px-12 py-9 background-gradient project-main">
            <router-view/>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CommentAddDialog from '@/components/dialogs/CommentAddDialog';
import TheCommentTimeline from '@/components/TheCommentTimeline';

export default {
    name: 'TheProject',

    components: {CommentAddDialog, TheCommentTimeline},

    data: () => ({
        tab: null,
    }),

    computed: {
        step: {
            get: function () {
                return this.$store.getters['workflow/step'];
            },
            set: function (value) {
                // Dit verandert de URL en past vervolgens workflow/step aan
                this.$gotoStep(value + '');
            }
        },

        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),

        ...mapState('project', {
            project: 'boundInstance',
        }),
    },

    mounted () {
        this.$store.commit('SET_NAVIGATION_MINI', false);
        this.$store.commit('SET_PHOTO_DRAWER', false);
    },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.stepper-view {
    position: absolute;
    right: 20px;
    top: 15px;
}

.comment-timeline {
    width: calc(100% - 15px);
}

.co-creation-steps {
    margin: 10px;
    padding: 10px 0 10px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    .compact {
        margin-right: 50px;
    }

    .stepper-view {
        right: 10px;
    }

    .v-stepper__step--active {
        &:after {
            right: 10px;
        }
    }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
    .project-main {
        padding: 0 !important;

        & > div {
            padding: 20px !important;
        }
    }
    .logo {
        display: none;
    }
    .co-creation-steps {
        .compact {
            margin-right: 40px;
        }
    }
    .stepper-view {
        right: 10px;
    }
}
</style>
