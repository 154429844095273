var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","light":"","max-width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",[_c('v-btn',_vm._g(_vm._b({staticClass:"boxed-icon",attrs:{"tile":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('workflow_design.manage'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-pencil-outline")])],1)],{"attrs":attrs,"on":on})]}}],null,true),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary"},[_vm._v(_vm._s(_vm.$t('workflow_design.manage_headline')))]),_c('design-list-tabular',{key:(_vm.designs.length ? _vm.designs[0].id : '0')+_vm.approvedDesign,staticClass:"mx-2 mt-2 mb-4",attrs:{"designs":_vm.designs,"append-column":_vm.$t('workflow_design.design_actions'),"prepend-column":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var design = ref.design;
return [(_vm.approvedDesign === design.$snap.ref.path)?_c('v-icon',{attrs:{"title":_vm.$t('workflow_design.approved_design')}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"append",fn:function(ref){
var design = ref.design;
return [_c('v-btn',{attrs:{"color":design.visible ? 'primary' : 'grey',"circle":"","icon":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.setVisibility(design.$snap.ref, !design.visible)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),(design.$snap.ref)?_c('delete-confirm-dialog',{attrs:{"heading":_vm.$t('design_delete.headline')},on:{"delete":function($event){return _vm.removeDesign(design.$snap.ref)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{attrs:{"title":design.deletable ? false : _vm.$t('design_delete.not_deletable')}},[_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!design.deletable,"color":"primary","circle":"","icon":"","tile":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}},{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.$t('design_delete.explanation', [design.name])))]},proxy:true}],null,true)}):_vm._e()]}}])}),_c('v-card-actions',[_c('book-navigation',{attrs:{"has-previous":_vm.designs.length && _vm.hasPrev,"has-next":_vm.designs.length && _vm.hasNext},on:{"previous":function($event){return _vm.prevPage(_vm.designs[0])},"next":function($event){return _vm.nextPage(_vm.designs[_vm.designs.length - 1])}}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 mb-2",attrs:{"text":""},on:{"click":function($event){_vm.isVisible = false}}},[_vm._v(_vm._s(_vm.$t('global.close')))]),_c('design-add-dialog',{attrs:{"project":_vm.project,"user":_vm.user},on:{"added":_vm.onNewDesignAdded},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"boxed-icon pr-0 mr-4 mb-2",attrs:{"disabled":!_vm.project.$ref,"color":"primary","tiles":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('global.create'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }