import { firestore } from 'firebase';
import { touchFirestoreProject } from '@/firebase/firestore/projects';

/**
 * Maak een template voor een formulier. Data bevat ingevulde formulierwaardes, maar is initieel leeg
 * @param {String} context - Waar het commentaar werd gemaakt (bijv.: "design 2")
 * @param {*} ctxLink - Verwijzing naar de context, bijvorbeeld een URL/link
 * @param {String} text
 * @param {Object} user - Grbuikersobject met uid en displayName properties
 * @param {Object} project - Projectgegevens met gevalideerde gebruikers
 * @param {Boolean} emphasized - Dit bericht benadrukken (past weergave aan)
 * @return {{context: String, ctxLink: *, text: String, uid: String, name: String, users: Array, emphasized: Boolean, timestamp: firebase.firestore.FieldValue}}
 */
export const createComment = ({context = '', ctxLink = null, text = '', user, project, emphasized = false}) => {
    if (!user || !project) {
        throw 'Arguments user and project are required to creating a comment';
    }

    return {
        context,
        ctxLink,
        text,
        uid: user.uid,
        name: user.displayName,
        users: project.users,
        emphasized: !!emphasized,
        timestamp: firestore.FieldValue.serverTimestamp(),
    };
};

/**
 * Maak een formulier aan in Firestore en geef een referentie terug
 * @param {DocumentReference<DocumentData>} refProject
 * @param comment
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
export const createFirestoreComment = (refProject, comment) => {
    if (!refProject) {
        return Promise.reject('Missing refProject to add comment to');
    }

    const ignorePromise = touchFirestoreProject(refProject);

    const collectionComments = refProject.collection('comments');
    return collectionComments.add(comment);
};
