import { mapState } from 'vuex';
import { ROLE_ARCHITECT, ROLE_DESIGNER, ROLE_SALES } from '@/utils';
import { userInGroupAdmin, userInGroupArchitect, userInGroupDesigners, userInGroupRodruza, userInGroupSales, userInGroupSuper } from '@/utils/authorization';

export default {
    data: () => ({
        ROLE_ARCHITECT: ROLE_ARCHITECT,
        ROLE_SALES: ROLE_SALES,
        ROLE_DESIGNER: ROLE_DESIGNER,
    }),

    // TODO: Als de noodzaak ontstaat user in de gehele app publiceren
    // Let op: probeer te vermijden en op te lossen met kant-en-klare functies die de store aanroepen
    // computed: {
    //     ...mapState('authentication', {
    //         user: 'user',
    //     }),
    // },

    methods: {
        $inGroupAdmin () {
            return userInGroupAdmin(this.$store.getters['authentication/getRoles']);
        },

        $inGroupArchitects () {
            return userInGroupArchitect(this.$store.getters['authentication/getRoles']);
        },

        $inGroupDesigners () {
            return userInGroupDesigners(this.$store.getters['authentication/getRoles']);
        },

        $inGroupSales () {
            return userInGroupSales(this.$store.getters['authentication/getRoles']);
        },

        $inGroupRodruza () {
            return userInGroupRodruza(this.$store.getters['authentication/getRoles']);
        },

        $inGroupSuper () {
            return userInGroupSuper(this.$store.getters['authentication/getRoles']);
        },
    },
};
