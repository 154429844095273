<template>
    <v-row no-gutters dense>
        <v-col cols="4" class="left-sidebar px-10 py-8">
            <v-card light class="mt-4 pa-3" elevation="0">
                <svg class="logo mb-6 float-right" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30.2 10.3" style="enable-background:new 0 0 30.2 10.3; width: 90px; position: absolute; right: 20px;" xml:space="preserve">
                        <g>
                            <path class="fill-logo" d="M4,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6H4L4,4.2z M5.4,4.8c0-0.7-0.5-1.1-1.2-1.1H3.4v3.2H4 V5.8l0.8,1.1h0.8L4.7,5.8C5.1,5.6,5.4,5.3,5.4,4.8"/>
                            <path class="fill-logo" d="M8,6.3c-0.6,0-1-0.4-1-1.1c0-0.6,0.4-1,1-1s1,0.4,1,1C9,5.9,8.6,6.3,8,6.3 M8,3.6c-1,0-1.7,0.7-1.7,1.7 c0,1,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7C9.7,4.3,9,3.6,8,3.6"/>
                            <path class="fill-logo" d="M11.5,4.2h0.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-0.2V4.2z M11.7,3.6h-0.9v3.2h0.9c1,0,1.6-0.6,1.6-1.6 C13.4,4.3,12.7,3.6,11.7,3.6"/>
                            <path class="fill-logo" d="M15.2,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6h-0.1L15.2,4.2z M16.6,4.8c0-0.7-0.5-1.1-1.2-1.1 h-0.8v3.2h0.6V5.8L16,6.9h0.8l-0.8-1.1C16.3,5.6,16.6,5.3,16.6,4.8"/>
                            <path class="fill-logo" d="M19.6,5.6c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.6-0.3-0.6-0.7v-2h-0.6v2c0,0.8,0.5,1.3,1.2,1.3 c0.7,0,1.2-0.5,1.2-1.3v-2h-0.6L19.6,5.6L19.6,5.6z"/>
                            <polygon class="fill-logo" points="21.4,3.6 21.4,4.2 22.7,4.2 21.3,6.9 23.6,6.9 23.6,6.3 22.3,6.3 23.7,3.6 		"/>
                            <path class="fill-logo" d="M26.3,5.8h-0.8l0.4-0.9L26.3,5.8z M25.9,3.4l-1.5,3.5h0.6l0.2-0.5h1.3l0.2,0.5h0.7L25.9,3.4z"/>
                        </g>
                    <path class="fill-logo" d="M0.6,0.6h29v9.1h-29V0.6z M0,10.3h30.2V0H0V10.3z"/>
                </svg>
                <h1 class="text-h1 pt-8">{{ $t('projects.heading') }}</h1>
                <project-list-tabular @click="setProject"/>
                <div style="height: 30px;" class="pt-12">
                    <v-divider class="mb-4"></v-divider>
                    <project-request-dialog v-if="$inGroupArchitects()"/>
                    <project-add-dialog :disabled="!$inGroupSales()" v-else/>
                </div>
            </v-card>
        </v-col>
        <v-col cols="8" class="px-12 py-2 background-gradient">
            <v-card dark class="mt-12 pa-5" style="min-height: 70px" outlined tile>
                <project-progress/>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ProjectListTabular from '@/components/ProjectListTabular';
import ProjectAddDialog from '@/components/dialogs/ProjectAddDialog';
import ProjectProgress from '@/components/ProjectProgress';
import ProjectRequestDialog from '@/components/dialogs/ProjectRequestDialog';

export default {
    name: 'TheProjects',

    components: {ProjectRequestDialog, ProjectProgress, ProjectAddDialog, ProjectListTabular},

    methods: {
        setProject (project) {
            this.$store.dispatch('project/setProject', project.id);
        },
    },

    mounted () {
        this.$store.commit('SET_NAVIGATION_MINI', false);
        this.$store.commit('SET_PHOTO_DRAWER', false);
    },
};
</script>

<style scoped lang="scss">

</style>
