<template>
    <v-dialog v-model="isVisible" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" class="boxed-icon" tile elevation="0">
                    {{ $t('global.upload') }}
                    <v-icon right>mdi-file-upload-outline</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ headline || $t('upload_dialog.headline') }}</v-card-title>
            <v-card-text v-if="explanation" class="tiemposText mt-4">{{ explanation }}</v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-list>
                    <v-list-item class="pa-1 pr-2" v-for="file in files" :key="file.id">
                        <v-file-input v-model="file.value" truncate-length="50" show-size :label="$t('upload.upload_file')" :placeholder="$t('upload.upload_file_placeholder')" @change="changeFile" :rules="uploadRules"/>
                    </v-list-item>
                </v-list>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn :disabled="!valuesEntered || !formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="uploadFile">
                    {{ $t('global.upload') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';
import { isImageType, restrictUploadSizeTo, RULES_URL_VALIDATE } from '@/utils';
import { uploadFiles } from '@/firebase/storage';

export default {
    name: 'UploadDialog',

    mixins: [toggleable],

    props: {
        headline: {
            type: String,
            default: '',
        },
        explanation: {
            type: String,
            default: '',
        },
        uploadFolder: {
            type: String,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        values () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },
    },
    data: function () {
        return {
            formValid: null,
            files: [],
            uploadRules: [
                file => isImageType(file?.type)
                    ? restrictUploadSizeTo(10)[0](file)
                    : restrictUploadSizeTo(250)[0](file),
            ],
        };
    },

    computed: {
        /**
         * Schakel verstuurknop in zodra er files/links toegevoegd zijn (lengte van een lijst is dan groter dan 1)
         * @return {boolean}
         */
        valuesEntered () {
            return this.files.length;
        },
    },

    created () {
        this.resetForm();
        this.updateFilesList();
    },

    methods: {
        resetForm () {
            this.files = [];
        },

        isImageType (type) {
            return isImageType(type);
        },

        /**
         * Change-event voor file-pickers
         */
        changeFile () {
            this.$nextTick(() => {
                this.updateFilesList();
            });
        },

        /**
         * Update de lijst met bestanden die geuploadet moeten worden
         * - Haal lege items uit de lijst
         * - Voegt een nieuw leeg item toe aan het einde
         */
        updateFilesList () {
            // Lege files verwijderen
            if (this.files.length) {
                for (let i = this.files.length - 1; i >= 0; i--) {
                    if (!this.files[i].value) {
                        this.files.splice(i, 1);
                    }
                }
            }

            if (this.multiple || !this.files.length) {
                this.files.push({
                    id: Math.round(Math.random() * 1000000000),
                    value: null,
                });
            }
        },

        /**
         * Upload het gekozen bestand
         */
        async uploadFile () {
            // Haal overbodige info uit de data en verwijder lege elementen
            const files = this.files.map(file => file.value).filter(notEmpty => notEmpty);

            this.$emit('beforeupload', files);
            const uploads = await uploadFiles(this.uploadFolder, files);
            this.$emit('afterupload', uploads);

            this.resetForm();
            this.updateFilesList();

            this.isVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables';

.v-list-item:nth-of-type(even) {
    background-color: $colors-grey-lighten5;
}
</style>
