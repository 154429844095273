<!--
    Volgende, vorige navigatie
    Dit is een wrapper rondom een fix die het Vuetify pagination uiterlijk
    heeft, maar zonder de knoppen voor tussenliggende knoppen.

    Gebruik:
        <book-navigation
            :has-previous="this.hasPreviousPage" @previous="this.onPreviousClicked"
            :has-next="this.hasNextPage" @next="this.onNextClicked"
        />
-->
<template>
    <!-- Mock v-pagination omdat deze donkere knoppen kent -->
    <div class="v-pagination v-pagination--circle theme--dark" style="width: auto">
        <v-btn :disabled="!hasPrevious" @click="$emit('previous')" small dark icon :class="['v-pagination__navigation', !hasPrevious ? 'v-pagination__navigation--disabled' : '']">
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn :disabled="!hasNext" @click="$emit('next')" small dark icon :class="['v-pagination__navigation', !hasNext ? 'v-pagination__navigation--disabled' : '']">
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'BookNavigation',

    props: {
        hasPrevious: {
            type: Boolean | Number,
            default: false,
        },
        hasNext: {
            type: Boolean | Number,
            default: false,
        },
    }
};
</script>

<style scoped>

</style>
