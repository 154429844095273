<template>
    <v-timeline dense clipped>
        <v-timeline-item fill-dot class="white--text mb-12" color="primary" large>
            <template #icon><span>{{ initials }}</span></template>
            <v-text-field class="tiemposText pt-3" outlined v-model="input" hide-details flat :label="$t('comments.label_add')" solo @keydown.enter="addComment">
            </v-text-field>
            <v-btn color="primary" class="mx-0 mt-3 boxed-icon oskarText float-right" tile small elevation="0" @click="addComment">
                {{ $t('global.create') }}
                <v-icon right>mdi-plus</v-icon>
            </v-btn>
        </v-timeline-item>
        <v-slide-x-transition group>
            <v-timeline-item v-for="comment in timeline" :key="comment.id" class="mb-4" :color="comment.uid === user.uid ? 'primary' : 'grey'" small>
                <v-card :class="comment.emphasized ? 'elevation-8' : 'elevation-2'">
                    <v-card-title class="overline">{{ comment.name }} - {{ comment.timestamp | formatDateTime }}</v-card-title>
                    <v-card-subtitle v-if="comment.context" class="tiemposText">({{ comment.context }})</v-card-subtitle>
                    <v-card-text class="tiemposText" v-text="comment.text"/>
                </v-card>
            </v-timeline-item>
        </v-slide-x-transition>
        <v-timeline-item v-if="!loadedAllComments" key="loading" class="mb-4" color="grey lighten-2" small>
            <v-card class="elevation-2">
                <v-card-text class="tiemposText">
                    <v-progress-circular v-intersect="loadComments" indeterminate color="primary"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
import { firestore } from 'firebase';
import { mapGetters, mapState } from 'vuex';
import { formatDateTime } from '@/utils/vue-filters';
import { userInGroupArchitect, userInGroupSuper } from '@/utils/authorization';
import { createComment, createFirestoreComment } from '@/firebase/firestore/projects/comments';

export default {
    name: 'TheCommentTimeline',

    data: () => ({
        comments: [],
        input: '',
        isLoading: false,
        loadedAllComments: false,
    }),

    computed: {
        timeline () {
            return this.comments;
        },

        ...mapState('project', {
            project: 'boundInstance',
        }),

        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
            initials: 'getAvatarInitials',
        }),
    },

    filters: {
        formatDateTime: formatDateTime,
    },

    mounted () {
        /** @var {Object} - Bevat de Firstore Query voor paginering van de commentaren */
        this.commentsPage = null;

        this.commentsListenerUnsubscribe = () => {};

        this.initiateComments();
    },

    beforeDestroy () {
        // Stop met luisteren naar updates van commentaren
        this.commentsListenerUnsubscribe();
    },

    methods: {
        /**
         * Voeg een commentaar toe aan de collectie van het huidige project
         * @return {Promise<any>}
         */
        addComment () {
            const text = this.input.trim();

            if (text === '') {
                return this.$store.dispatch('statusMessage/info', this.$t('comments.no_empty_comment_status_message'));
            }

            const comment = createComment({
                context: '',
                text,
                user: this.user,
                project: this.project,
                emphasized: userInGroupArchitect(this.roles),
            });

            createFirestoreComment(this.project.$ref, comment);

            this.input = '';
        },

        /**
         * Haal 1 pagina op met resultaten (na scrollen)
         * @return {Promise<void>}
         */
        async loadComments () {
            if (this.loadedAllComments || this.isLoading) {
                return;
            }

            this.isLoading = true;

            const documentSnapshots = await this.commentsPage.get();

            this.comments = [...this.comments, ...documentSnapshots.docs.map(comment => ({...comment.data(), id: comment.id}))];

            if (documentSnapshots.docs.length) {
                // Bepaal het laatste resultaat zodat we verder kunnen gaan vanaf dat punt na scrollen
                const commentsCursor = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                this.commentsPage = this.commentsPage.startAfter(commentsCursor);
            } else {
                this.loadedAllComments = true;
            }

            this.isLoading = false;
        },

        /**
         * Stel objecten in voor infinite scroll & live update
         * @return {Promise<void>}
         */
        async initiateComments () {
            const refProject = this.project.$ref;

            let collectionComments = refProject.collection('comments');

            if (!userInGroupSuper(this.roles)) {
                collectionComments = collectionComments.where('users', 'array-contains', this.user.uid);
            }

            // Stel de query in voor het ophalen van 1 "pagina" aan commentaren
            this.commentsPage = collectionComments
                .orderBy('timestamp', 'desc')
                .limit(12);

            // Voeg een listener toe die nieuwe berichten toevoegt aan het
            // begin van de lijst zodra ze ontstaan.
            this.commentsListenerUnsubscribe = collectionComments
                .where('timestamp', '>', firestore.Timestamp.now())
                .onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === 'added') {
                            this.comments.unshift({...change.doc.data(), id: change.doc.id});
                        }
                    });
                });
        },
    },
};
</script>

<style scoped>

</style>
