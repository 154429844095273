<template>
    <v-dialog v-model="isVisible" persistent light max-width="450">
        <v-card>
            <v-card-title class="headline primary">{{ $t('invite_user_dialog.created_invite') }}</v-card-title>
            <v-card-text class="tiemposText mt-4">
                {{ $t('invite_user_dialog.created_invite_body_text') }}
            </v-card-text>
            <v-card-text class="tiemposText">
                {{ inviteUrl }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="isVisible = false">{{ $t('global.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';

export default {
    name: 'UserInviteAddedDialog',

    mixins: [toggleable],

    props: {
        inviteUrl: {
            type: String | null,
            required: true,
        }
    },

    data: () => ({
        showDialog: false,
    }),
};
</script>

<style scoped>

</style>
