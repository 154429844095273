<template>
    <v-dialog v-model="isVisible" persistent light max-width="650">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon" tile elevation="0">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('design_add.headline') }}</v-card-title>
            <v-card-text class="tiemposText mt-4">{{ $t('design_add.explanation') }}</v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-list>
                    <v-list-item class="pa-1 pr-2">
                        <v-text-field class="ml-1" :placeholder="$t('design_add.name_placeholder')" :label="$t('design_add.name_label')" required :rules="designNameRules" v-model="name"/>
                    </v-list-item>
                    <v-list-item class="pa-1 pr-2" v-for="file in files" :key="file.type">
                        <v-file-input v-model="file.value" truncate-length="50" show-size :label="file.title" :placeholder="$t('design_add.upload_file_placeholder', [file.title])" @change="changeFile" :rules="uploadRules"/>
                    </v-list-item>
                </v-list>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn :disabled="!valuesEntered || !formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="addDesign" :loading="isUploading">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';
import { restrictUploadSizeTo, RULES_FIELD_REQUIRED } from '@/utils';
import { DESIGN_TEXTURES, uploadFilesAndAddFirestoreDesign } from '@/firebase/firestore/projects/designs';

export default {
    name: 'DesignAddDialog',

    mixins: [toggleable],

    props: {
        project: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    watch: {
        values () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        isVisible () {
            // De $ref moet altijd bestaan. Bij voorkeur afdwingen met v-if="xxx.$ref" of :disabled="!xxx.$ref"
            // Hier het dialoogvenster hier sluiten is niet mogelijk omdat dat een lus veroorzaakt met deze functie zelf.
            if (!this.project.$ref) {
                this.$store.dispatch('statusMessage/error', 'An error occurred while opening the upload dialog. Please reopen to try again.');
            }
        },
    },

    data: function () {
        return {
            formValid: null,
            name: '',
            isUploading: false,
            files: [],
            uploadRules: [
                file => restrictUploadSizeTo(10)[0](file),
            ],
            designNameRules: [
                ...RULES_FIELD_REQUIRED,
            ]
        };
    },

    computed: {
        /**
         * Schakel verstuurknop in zodra alle files toegevoegd zijn
         * @return {boolean}
         */
        valuesEntered () {
            return this.files.filter(file => file.value).length === this.files.length;
        },
    },

    created () {
        this.resetForm();
    },

    methods: {
        resetForm () {
            this.name = '';
            this.isUploading = false,
                this.files = DESIGN_TEXTURES.map(texture => ({
                    type: texture,
                    title: this.$t('design_add.' + texture),
                    value: null,
                }));
        },

        /**
         * Change-event voor file-pickers
         */
        changeFile () {
            // TODO: check of dit nodig is
            this.$refs.form.validate();
        },

        /**
         * Voeg een rij toe aan het moodboard
         */
        async addDesign () {
            this.isUploading = true;

            const refDesign = await uploadFilesAndAddFirestoreDesign(this.project.$ref, {
                files: this.files.filter(file => file.value),
                name: this.name,
                project: this.project,
                user: this.user,
            });

            this.resetForm();

            this.isVisible = false;

            this.$emit('added', [refDesign]);
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables';

.v-list-item:nth-of-type(even) {
    background-color: $colors-grey-lighten5;
}
</style>
