<template>
    <v-snackbar top :color="$store.getters['statusMessage/status']" @click="$store.commit('statusMessage/SET_IS_VISIBLE', false)" v-model="isVisible">
        <div v-html="$store.getters['statusMessage/message']"></div>
    </v-snackbar>
</template>

<script>
export default {
    name: 'TheStatusMessage',

    computed: {
        isVisible: {
            set (value) {
                return this.$store.commit('statusMessage/SET_IS_VISIBLE', value);
            },
            get () {
                return this.$store.getters['statusMessage/isVisible'];
            }
        },
    }
};
</script>
