import { firestore } from 'firebase';
import { touchFirestoreProject } from '@/firebase/firestore/projects';
import { deleteFolder, uploadFiles } from '@/firebase/storage';
import { newId } from '@/firebase';

/** @var {Array} - Lijst met bekende texturen die gebruikt worden in de 3D-weergave */
export const DESIGN_TEXTURES = [
    'map',
    // 'lightMap',
    // 'aoMap',
    // 'emissiveMap',
    // 'bumpMap',
    'normalMap',
    'displacementMap',
    'roughnessMap',
    'metalnessMap',
    // 'alphaMap',
    // 'envMap',
    // 'clearcoatMap',
    // 'clearcoatRoughnessMap',
    // 'clearcoatNormalMap',
    // 'transmissionMap',
];

/**
 * Maak een template voor een design.
 * @param {String} name
 * @param {Object} files - Lijst met bestanden die aan het design gekoppeld zijn
 * @param {Object} user - Gebruikersobject met uid en displayName properties
 * @param {Object} project - Projectgegevens met gevalideerde gebruikers
 * @return {{context: String, ctxLink: *, text: String, uid: String, name: String, users: Array, emphasized: Boolean, timestamp: firebase.firestore.FieldValue}}
 */
const createDesign = ({name = '', files = [], user, project}) => {
    if (!user || !project) {
        throw 'Arguments user and project are required to creating a design';
    }

    const validFiles = {};
    files.forEach(file => {
        if (!file.meta?.type || DESIGN_TEXTURES.indexOf(file.meta.type) === -1) {
            return;
        }

        validFiles[file.meta.type] = file;
    });

    return {
        name,
        files: validFiles,
        uid: user.uid,
        users: project.users,
        deletable: true,
        visible: true,
        timestamp: firestore.FieldValue.serverTimestamp(),
    };
};

/**
 * Maak een design aan in Firestore en geef een referentie terug
 * @param {DocumentReference<DocumentData>} refProject
 * @param design
 * @param {String|null} designUid
 * @returns {Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>}
 */
const createFirestoreDesign = (refProject, design, designUid = null) => {
    if (!refProject) {
        return Promise.reject('Missing refProject to add design to');
    }

    const ignorePromise = touchFirestoreProject(refProject);

    const collectionDesigns = refProject.collection('designs');

    if (designUid) {
        const docDesign = collectionDesigns.doc(designUid);
        docDesign.set(design);

        // Bevat niet onmiddelijk de data want set() is asynchroon
        return docDesign;
    }

    return collectionDesigns.add(design);
};

/**
 * Voegt de bestanden voor het gevraagde design toe aan de storage
 * @param projectId
 * @param designId
 * @param files
 * @return {Promise<[]>}
 */
const uploadDesignFiles = async (projectId, designId, files) => uploadFiles(`projects/${projectId}/designs/${designId}`, files, {createLazy: false, createThumb: false});

/**
 * Voeg een entry toe aan de design.
 * Een entry bestaat uit een naam, bestanden, user info en een timestamp
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refProject - Firebase documentreferentie naar het poject waar het design opgeslagen moet worden
 * @param {Object} entryData
 * @return {Boolean|Promise<DocumentReference>} - Referentie naar het nieuwe design
 */
export const uploadFilesAndAddFirestoreDesign = async (refProject, entryData) => {
    const docProject = await refProject.get();

    if (!docProject.exists) {
        return false;
    }

    // if (!checkFiles(entryData.files)) {
    //
    // }

    const uploads = entryData.files.map(file => {
        const blobFile = file.value;

        // Deze meta-data is noodzakelijk om de bestanden te herkennen
        blobFile.meta = {
            type: file.type,
            title: file.title,
        };

        return blobFile;
    });

    const designEntryId = newId();
    const files = await uploadDesignFiles(refProject.id, designEntryId, uploads);

    return createFirestoreDesign(refProject, createDesign({...entryData, files}), designEntryId);
};

/**
 * Verwijder een design en de bijbehorende bestanden
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refDesign
 * @return {Promise<>}
 */
export const removeFirestoreDesignAndRemoveFiles = async refDesign => {
    // Duck-typing voor een DocumentReference zodat we het pad en een verwijder-functie hebben
    if (!refDesign.path || typeof refDesign.delete !== 'function') {
        return Promise.reject('Pad naar design of functie "delete" ontbreekt');
    }

    await deleteFolder(refDesign.path);
    return refDesign.delete();
};

/**
 * Werk design bij
 * @param {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} refDesign
 * @param {Object} data
 * @return {Promise<never>|*}
 */
export const updateFirestoreDesign = (refDesign, data) => {
    if (!refDesign) {
        return Promise.reject('Missing refDesign, can\'t update');
    }

    return refDesign.update(data);
};
