<template>
    <v-app>
        <div class="too-narrow">
            <div class="too-narrow__info tiemposText">
                <v-alert class="mt-1 pt-3 pb-0 mb-2" dense prominent type="error">
                    <h4 class="text-h4">{{ $t('global.window-too-narrow-heading') }}</h4>
                    <p class="tiemposText tiemposText--bold">{{ $t('global.window-too-narrow-text') }}</p>
                </v-alert>
            </div>
        </div>
        <v-navigation-drawer app permanent :mini-variant.sync="navigationIsMini">
            <v-row class="pa-1 ma-0" style="width: 300px;">
                <v-col cols="2" class="pa-0 ma-0">
                    <v-app-bar-nav-icon large @click="navigationIsMini = !navigationIsMini">
                        <v-icon v-if="navigationIsMini">mdi-menu</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                    </v-app-bar-nav-icon>
                </v-col>
                <v-col cols="6" class="pt-2">MENU</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-1 ma-0" style="width: 300px;">
                <v-col cols="10" offset="2" class="pt-10">
                    <svg class="logo-white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30.2 10.3" style="enable-background:new 0 0 30.2 10.3; width: 90px;" xml:space="preserve">
                        <g>
                            <path class="fill-logo" d="M4,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6H4L4,4.2z M5.4,4.8c0-0.7-0.5-1.1-1.2-1.1H3.4v3.2H4 V5.8l0.8,1.1h0.8L4.7,5.8C5.1,5.6,5.4,5.3,5.4,4.8"/>
                            <path class="fill-logo" d="M8,6.3c-0.6,0-1-0.4-1-1.1c0-0.6,0.4-1,1-1s1,0.4,1,1C9,5.9,8.6,6.3,8,6.3 M8,3.6c-1,0-1.7,0.7-1.7,1.7 c0,1,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7C9.7,4.3,9,3.6,8,3.6"/>
                            <path class="fill-logo" d="M11.5,4.2h0.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-0.2V4.2z M11.7,3.6h-0.9v3.2h0.9c1,0,1.6-0.6,1.6-1.6 C13.4,4.3,12.7,3.6,11.7,3.6"/>
                            <path class="fill-logo" d="M15.2,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6h-0.1L15.2,4.2z M16.6,4.8c0-0.7-0.5-1.1-1.2-1.1 h-0.8v3.2h0.6V5.8L16,6.9h0.8l-0.8-1.1C16.3,5.6,16.6,5.3,16.6,4.8"/>
                            <path class="fill-logo" d="M19.6,5.6c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.6-0.3-0.6-0.7v-2h-0.6v2c0,0.8,0.5,1.3,1.2,1.3 c0.7,0,1.2-0.5,1.2-1.3v-2h-0.6L19.6,5.6L19.6,5.6z"/>
                            <polygon class="fill-logo" points="21.4,3.6 21.4,4.2 22.7,4.2 21.3,6.9 23.6,6.9 23.6,6.3 22.3,6.3 23.7,3.6 		"/>
                            <path class="fill-logo" d="M26.3,5.8h-0.8l0.4-0.9L26.3,5.8z M25.9,3.4l-1.5,3.5h0.6l0.2-0.5h1.3l0.2,0.5h0.7L25.9,3.4z"/>
                        </g>
                        <path class="fill-logo" d="M0.6,0.6h29v9.1h-29V0.6z M0,10.3h30.2V0H0V10.3z"/>
                    </svg>
                    <h2 class="text-h2 pt-3 app__name">Ceramic<br>Design<br>Lab</h2>
                </v-col>
            </v-row>
            <v-row class="pl-1 ma-0" style="width: 300px;">
                <v-col cols="10" offset="2" class="mainmenu">
                    <a class="mainmenu__item overline" @click="$router.push('/login')" v-if="!currentUser.uid">
                        <v-icon x-small>mdi-arrow-right</v-icon>
                        {{ $t('login.label_button') }}
                    </a>
                    <span class="mainmenu__item overline" v-else>
                        <v-icon x-small>mdi-arrow-right</v-icon>
                        {{ $t('login.label_button') }}
                    </span>
                    <a :class="[{inactive: !currentUser.uid}, 'mainmenu__item', 'overline']" @click="$router.push('/projects')" v-if="currentUser.uid">
                        <v-icon x-small>mdi-arrow-right</v-icon>
                        {{ $t('projects.heading') }}
                    </a>
                    <span class="mainmenu__item overline" v-else>
                        <v-icon x-small>mdi-arrow-right</v-icon>
                        {{ $t('projects.heading') }}
                    </span>
                    <a :class="[{inactive: !currentUser.uid}, 'mainmenu__item', 'overline']" v-if="$inGroupAdmin() || $inGroupDesigners()" @click="$router.push('/dashboard')">
                        <v-icon x-small>mdi-arrow-right</v-icon>
                        Dashboard
                    </a>
                    <div v-if="NODE_ENV === 'development'" class="app__userselect mt-1">
                        <v-select v-model="currentUser" @change="_dev_setUser" :items="_dev_getUsers" item-text="displayName" item-value="role" return-object label="TEST"></v-select>
                    </div>
                </v-col>
            </v-row>
            <template v-slot:append>
                <v-row class="pa-2 ma-0" style="width: 300px;">
                    <v-col cols="2" class="pt-12">{{ language }}</v-col>
                    <v-col cols="10">
                        <div class="overline">Select LANGUAGE:</div>
                        <v-btn class="mr-3" outlined x-small :color="language === 'en' ? 'primary' : void(0)" @click="$store.dispatch('locale/setLanguage', 'en')">English</v-btn>
                        <v-btn outlined x-small :color="language === 'nl' ? 'primary' : void(0)" @click="$store.dispatch('locale/setLanguage', 'nl')">Nederlands</v-btn>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="width: 290px;">
                    <v-col cols="2" class="pt-7">
                        <v-icon large>mdi-account-circle</v-icon>
                    </v-col>
                    <v-col cols="10 pl-4">
                        <v-list-item-content>
                            <v-list-item-title class="title"> {{ currentUser.displayName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="pt-5">
                                <!-- v-btn outlined x-small color="primary" class="float-right mr-12 mb-3" v-if="!currentUser.emailVerified" @click="resendVerificationEmail">Resend verification email</v-btn -->
                                <v-btn outlined x-small color="white" class="mr-12" v-if="currentUser.uid" @click="signOut">Logout</v-btn>
                                <br>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>
        <v-main dark>
            <router-view/>
        </v-main>
        <v-navigation-drawer app permanent right mini-variant v-if="showPhotoDrawer" class="side-menu-right"/>
        <the-status-message/>
        <the-image-full-dialog/>
    </v-app>
</template>

<script>
import { auth } from 'firebase';
import { mapState } from 'vuex';
import TheStatusMessage from '@/components/TheStatusMessage';
import TheImageFullDialog from '@/components/dialogs/TheImageFullDialog';

export default {
    name: 'App',

    components: {TheImageFullDialog, TheStatusMessage},

    data: () => ({
        mini: true,
        currentUser: {},
    }),

    computed: {
        _dev_getUsers: {
            get: function () {
                return this.$store.getters._dev_getUsers;
            },
            set: function (value) {
                this.$store.commit('SET_USERS', value);
            },
        },
        navigationIsMini: {
            get: function () {
                return this.$store.state.navigationIsMini;
            },
            set: function (value) {
                this.$store.commit('SET_NAVIGATION_MINI', value);
            },
        },
        showPhotoDrawer: {
            get: function () {
                if (window.innerWidth < 1200) return false;
                return this.$store.getters.getPhotoDrawer;
            },
            set: function (value) {
                this.$store.commit('SET_PHOTO_DRAWER', value);
            },
        },
        ...mapState('locale', {
            language: 'language',
        }),
        ...mapState('project', {
            boundProjectId: 'boundInstanceId',
        }),
    },
    watch: {
        '$store.state.authentication.user': function () {
            this.currentUser = this.$store.getters['authentication/getUser'];
        },
    },
    mounted () {
        this.$store.dispatch('authentication/monitorUserStatus');
        this.$store.dispatch('workflow/initialize');

        this.$store.commit('SET_NAVIGATION_MINI', true);
        this.$store.commit('SET_PHOTO_DRAWER', false);
    },
    methods: {
        _dev_setUser () {
            let newUser = {...this.currentUser};
            if (!newUser.password) {
                newUser = this._dev_getUsers.find(user => newUser.email === user.email);
            }

            // Gebruik de gegevens die in de app zijn opgeslagen
            this.$store.dispatch('authentication/userSignIn', {
                email: newUser.email,
                password: newUser.password
            });
        },

        resendVerificationEmail () {
            auth().currentUser.sendEmailVerification().catch(error => {
                // Toon melding van firebase aan gebruiker
                this.$store.dispatch('statusMessage/error', error, {root: true});
            });
        },

        signOut () {
            this.$store.dispatch('authentication/userSignOut');
        },
    },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@font-face {
    font-family: "TiemposText";
    font-weight: normal;
    src: url("/assets/fonts/TiemposText-Regular.otf") format("opentype");
}

@font-face {
    font-family: "TiemposHeadline";
    font-weight: 700;
    src: url("/assets/fonts/TiemposHeadline-Bold.otf") format("opentype");
}

@font-face {
    font-family: "TiemposHeadline";
    font-weight: 400;
    src: url("/assets/fonts/TiemposHeadline-Regular.otf") format("opentype");
}

@font-face {
    font-family: "TiemposHeadline";
    font-weight: 200;
    src: url("/assets/fonts/TiemposHeadline-Light.otf") format("opentype");
}

@font-face {
    font-family: Oskar;
    font-weight: normal;
    text-transform: uppercase;
    src: url("/assets/fonts/oskar-oneregular.otf") format("opentype");
}

// Melding dat het scherm smaller is dan de minimale breedte
// Zie media queries voor de actuele afmeting
.too-narrow {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);

    h1 {
        font-size: 30px;
        padding-bottom: 20px;
    }

    &__info {
        background: white;
        color: black;
        width: 100%;
        max-width: 500px;
        margin: auto;
        margin-top: 10vh;
        padding: 30px;
    }

    // Schakelt de melding uit tijdens development
    .app-mode--deveopment & {
        display: none !important;
    }
}

.background-gradient {
    background: linear-gradient(180deg, rgba(252, 252, 252, 0.1) 0%, rgba(0, 0, 0, 0.2) 50%);

    &--halve-reverse {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(255, 255, 255, 0.07) 65%, rgba(0, 0, 0, 0) 80%);
    }

    &--reverse {
        background: linear-gradient(-180deg, rgba(252, 252, 252, 0.1) 0%, rgba(0, 0, 0, 0.2) 50%);
    }

    &--sharp-halve {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(75, 75, 75, 0.5) 50%, rgba(0, 0, 0, 0) 50%);
    }
}

.transparant-tabs {
    .v-tabs-bar {
        background-color: transparent !important;
    }
}

.theme--dark.v-sheet--outlined {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.text-left {
    padding-left: 0 !important;
}

.side-menu-right {
    background: url("/assets/img/side-background.jpg");
    background-size: cover;
    background-position: right;
}

.background-bricks {
    background: url("/assets/img/side-background.jpg");
    background-size: cover;
    background-position: right;
}

.tiemposText { // Voor basis platte text in de tiempos
    font-family: "TiemposText";
    font-size: 0.82rem;
    line-height: 1.3rem;
    text-transform: none;

    &--bold {
        font-weight: 700;
    }
}

.v-label {
    text-transform: none !important;
}

.v-card__title {
    word-break: break-word;
}

.oskarText {
    font-family: "Oskar";
}

.v-card__title.primary {
    color: white;
}

.v-text-field {
    input, textarea {
        font-family: "TiemposText";
    }
}

.left-sidebar { // Linker sidebar in het wit
    background-color: white;
    min-height: 100vh;
}

.v-stepper__label small {
    text-transform: uppercase;
}

.v-stepper__step--complete {
    .v-stepper__step__step.primary {
        background-color: #eee !important;
        border: none;
    }
}

.v-stepper__step--editable {
    .v-stepper__step__step {
        background-color: rgba(90, 165, 90, 0.7) !important;

        &:before {
            content: "\F0054";
            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: 13px;
            position: absolute;
            color: black;
            transform: translateX(-20px);
        }
    }

    .v-stepper__step__step.primary {
        background-color: #5aa55a !important;
        border: none;
    }
}

.v-stepper__step--active {
    &:after {
        content: "\F0054";
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: 30px;
        position: absolute;
        color: var(--v-primary-base);
        right: 20px;
    }

    .v-btn--icon {
        display: none;
    }
}

.v-slide-group__next, .v-slide-group__prev {
    display: none !important;
}

.v-stepper__step--inactive {
    .v-stepper__step__step {
        border: 1px solid rgba(0, 0, 0, 0.4);
    }
}

.v-stepper__step__step .v-icon.v-icon {
    font-size: 1rem !important;
}

.v-stepper--vertical .v-stepper__step {
    padding: 10px 0;
}

.v-stepper--vertical .v-stepper__content {
    padding: 10px 60px 5px 23px;
    width: auto;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: 0px -36px 0px 36px;
}

.theme--light.v-stepper .v-stepper__step--editable:hover {
    background: linear-gradient(169deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 3%) 100%) !important;
}

.v-textarea {
    .v-label:not(.v-label--active) {
        opacity: 0.3;
        text-transform: uppercase;
    }
}

.v-label {
    text-transform: uppercase;
}

input::placeholder,
textarea::placeholder {
    font-family: 'Oskar', serif;
    opacity: 0.3;
    text-transform: uppercase;
}

.v-data-table__wrapper {
    th {
        text-transform: uppercase;
    }
}

.v-btn--block {
    display: block;
    background-color: rgba(125, 125, 125, 0.3);
    justify-content: left;
}

.boxed-icon { // button's kunnen een icoontje hebben met een vierkantje er om heen
    .v-icon--right {
        margin-left: 18px !important;
    }

    .mdi:before, .mdi-set {
        background: black;
        border: 6px solid black;
        margin-left: 10px;
    }

    .v-icon--left {
        margin-right: 18px !important;

        &:before, &.mdi-set {
            margin-left: 4px;
            padding-left: 0px;
            padding-right: 0px;
            margin-right: 10px;
        }
    }

    &.v-btn--disabled {
        .mdi:before, .mdi-set {
            color: #555;
        }
    }

    &.v-btn--outlined {
        .mdi:before, .mdi-set {
            background-color: transparent;
            border: none;
            border-left: 1px solid;
            //border-color: var(--v-primary-base);

            margin-top: -15px;
            margin-bottom: -15px;
            height: calc(100% + 17px);
            margin-left: 0;
            padding-left: 6px;
            padding-top: 5px;
        }

        .v-icon--left {
            &:before, &.mdi-set {
                border: none;
                border-right: 1px solid;
                //border-color: var(--v-primary-base);
                margin-left: 10px;
                padding-left: 0px;
                padding-right: 6px;
                margin-right: 12px;
            }
        }
    }

    &.v-size--small {
        .mdi:before, .mdi-set {
            border: 2px solid black;
        }

        &.v-btn--outlined {
            .mdi:before, .mdi-set {
                border: none;
                border-left: 1px solid;
                //border-color: var(--v-primary-base);

                margin-top: -8px;
                margin-bottom: -8px;
                height: calc(100% + 10px);
                padding-left: 2px;
                padding-top: 2px;
            }
        }
    }
}

.mainmenu {
    &__item {
        display: block;
        text-decoration: none;
        color: white !important;

        &.inactive {
            opacity: 0.1;
        }
    }
}

.fill-logo {
    fill: black;
}

.logo-white {
    .fill-logo {
        fill: white;
    }
}

.app__userselect {
    display: none;
    max-width: 100px;
    max-height: 25px;

    // Schakelt de melding uit tijdens development
    .app-mode--development & {
        display: block;
    }
}

@media only screen and (max-width: 1023px) {
    .too-narrow {
        display: block;
    }
}

@media only screen and (max-height: 659px) {
    .app__name {
        display: none;
    }
}

@media only screen and (max-height: 599px) {
    .too-narrow {
        display: block;
    }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-application {
        .px-10.left-sidebar {
            padding-right: 0px !important;
            padding-left: 10px !important;
        }

        .text-h1 {
            font-size: 2rem !important;
        }

        .text-h2 {
            font-size: 1.4rem !important;
        }

        .left-sidebar {
            position: relative;
            z-index: 2;
        }
    }

    .v-stepper__step--active {
        &:after {
            right: 10px;
        }
    }
}

.project-step-link {
    &:hover {
        cursor: pointer;
    }
}

span.mainmenu__item {
    opacity: 0.5;
    cursor: default;
}
</style>
