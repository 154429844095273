import { saveAs } from 'file-saver';
import { isImageType } from '@/utils';

export default {
    methods: {
        $isImageType (type) {
            return isImageType(type);
        },

        $compileUrl (url) {
            if (!url.match('^https?://')) {
                url = 'http://' + url;
            }

            return url;
        },

        $popupImage (fileEntry) {
            return this.$store.dispatch('imagePopup/show', {
                src: fileEntry.url || fileEntry.thumb,
                lazySrc: fileEntry.lazy || fileEntry.thumb,
            });
        },

        $openUrl (fileEntry) {
            window.open(this.$compileUrl(fileEntry.url), '_blank');
        },

        $downloadFile (fileEntry) {
            if (typeof fileEntry.url !== 'string' || typeof fileEntry.type !== 'string') {
                this.$store.dispatch('statusMessage/info', this.$t('download.no_download_available'));
            }

            saveAs(fileEntry.url, fileEntry.title);
        },

        /**
         * Bepaal het icoon voor bestand gebaseerd op het type bestand
         * @param {Object} fileEntry - FileEntry voor opslag van bestandsverwijzingen in Firestore
         * @return {String}
         */
        $iconByType (fileEntry) {
            switch (fileEntry.type) {
                case 'application/pdf':
                    return 'mdi-file-pdf-outline';

                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'mdi-file-word-outline';

                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'mdi-file-excel-outline';

                case 'image/bmp':
                case 'image/gif':
                case 'image/jpeg':
                case 'image/png':
                    return 'mdi-file-image-outline';

                default:
                    return 'mdi-file-document-outline';
            }
        },
    },
};
