<template>
    <v-dialog v-model="showDialog" persistent light max-width="450">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :color="userCount === 0 ? 'primary' : ''" class="ml-3 my-5 boxed-icon" :title="`Current number of users: ` + userCount">
                {{ $t('project.invite') }}
                <v-icon right>mdi-card-account-details-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-form ref="form" @submit.prevent="createInvite">
                <v-card-title class="headline primary">{{ $t('invite_user_dialog.create_invite') }}</v-card-title>
                <v-card-text class="tiemposText mt-4">
                    {{ $t('invite_user_dialog.create_invite_body_text') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showDialog = false">{{ $t('global.cancel') }}</v-btn>
                    <v-btn color="primary" type="submit" class="boxed-icon pr-0 mr-4" tiles elevation="0">
                        {{ $t('global.create') }}
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <user-invite-added-dialog v-model="showCreatedDialog" :invite-url="inviteUrl"/>
    </v-dialog>
</template>

<script>
import UserInviteAddedDialog from '@/components/dialogs/UserInviteAddedDialog';
import { createFirestoreInvite } from '@/firebase/firestore/invites';
import { refProjects } from '@/firebase';

export default {
    name: 'UserInviteAddDialog',
    components: {UserInviteAddedDialog},
    props: {
        projectId: {
            type: String,
            required: true,
        },
        userCount: {
            type: Number,
            default: 0,
            validator: val => val > -1,
        }
    },

    data: function () {
        return {
            showDialog: false,
            showCreatedDialog: false,
            inviteUrl: null,
        };
    },

    methods: {
        async createInvite () {
            // Maak nieuwe invite voor project aan
            // Haal hiervoor een referentie op bij Firebase (de store kan geen referenties opslaan vanwege mutaties)
            const refProject = refProjects.doc(this.projectId);
            const invite = await createFirestoreInvite({refProject});

            // Toon de nieuwe invite middels het dialoogvenster
            this.inviteUrl = window.document.location.origin + '/invite/' + invite.id;
            this.showCreatedDialog = true;

            // Sluit dit dialoogvenste
            this.showDialog = false;
        },
    },
};
</script>

<style scoped>

</style>
