<template>
    <v-dialog v-model="isVisible" persistent light max-width="450">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" class="boxed-icon" color="primary" tile elevation="0">
                    {{ $t('global.delete') }}
                    <v-icon right>mdi-trash-can-outline</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ heading }}</v-card-title>
            <v-card-text v-if="$slots.body" class="tiemposText mt-4">
                <slot name="body"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="$emit('delete'); isVisible = false">
                    {{ $t('global.delete') }}
                    <v-icon right>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';

export default {
    name: 'DeleteConfirmDialog',

    mixins: [toggleable],

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
