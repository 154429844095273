import { toDate } from '@/utils/datetime';

/**
 * Kort een token in tot de eerste 4 karakters gevolgd door .. en dan de laatste 4.
 * @param token
 * @returns {string}
 */
export const shortenToken = token => token.substr(0, 4) + '..' + token.substr(-4);

/**
 * Zet de waardes -1, 0 en 1 om in icoon-kleuren zwart, grijs en groen
 * @param {Number} status
 * @returns {string}
 */
export const stepIconColor = status => status === 1 ? 'green' : status === 0 ? 'gray' : 'black';

/**
 * Formateer een datum-object als datum in templates
 * @param {Date} value
 * @return {string|*}
 */
export const formatDate = value => value ? toDate(value).toLocaleString('nl-NL', {dateStyle: 'short'}) : void (0);

/**
 * Formateer een datum-object als datum-tijd in templates
 * @param {Date} value
 * @return {string|*}
 */
export const formatDateTime = value => value ? toDate(value).toLocaleString('nl-NL', {dateStyle: 'short', timeStyle: 'short'}) : void (0);
